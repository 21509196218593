<div class="custom-header-bpost background hidden-xs custom-height">
  <div id="mybpostbanner" *ngIf="(isMobile||isTablet)&&showAppPromoBanner&&!iPhone">
    <div id="mybpostleft">
      <div id="mybpostclose" (click)="closeAppPromoBanner()"></div>
      <div id="mybposticon"></div>
    </div>
    <div id="mybpostcenter">
      <span>{{'my_bpost.downloadMyBpostKey' | translate}}</span>
      <br>
      <span>{{'my_bpost.appName' | translate}}</span>
      <br>
      <span *ngIf="android">{{'my_bpost.androidOSKey' | translate}}</span>
      <!-- <span *ngIf="iPhone">{{'my_bpost.iOSKey' | translate}}</span> -->
      <span *ngIf="appStoreNameGeneric">{{'my_bpost.appStoreNameGeneric' | translate}}</span>
    </div>
    <div *ngIf="android" id="mybpostright">
      <a (click)="openApp()" target="_blank">{{'my_bpost.downloadButton' | translate}}</a>
    </div>
  </div>
  <div class="custom-red-border">
    <!-- <div class="container"> -->
    <div class="col-sm-12 col-md-offset-1 col-md-6 col-lg-offset-1 col-lg-6">
      <!-- <div *ngIf="showLogo" class="logo_new_parent">
                <img src="/assets/images/bpost-logo-small.png" (click)="goHome()" class="logo_new">
            </div> -->
    </div>
    <div class="col-md-4 col-lg-4">
      <div class="dropdown pull-right" [ngClass]="{'open' : toggle==true}">
        <a aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" class="dropdown-toggle" href="#" (click)="toggle=!toggle">
          <span id="selected">{{'language_header.lang' | translate }}</span>
          <span id="selected"></span><span class="custom-caret"></span></a>
        <ul class="dropdown-menu">
          <li><a id="useLanguage_one" href="#" (click)="toggle=!toggle" (click)="useLanguage('nl')">{{'language_header.lang_nl' | translate }}</a></li>
          <li><a href="#" (click)="toggle=!toggle" (click)="useLanguage('fr')">{{'language_header.lang_fr' | translate }}</a></li>
          <li><a href="#" (click)="toggle=!toggle" (click)="useLanguage('en')">{{'language_header.lang_en' | translate }}</a></li>
        </ul>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
<div class="cursor_pointer custom-header-bpost visible-xs">

  <div id="mybpostbanner" *ngIf="(isMobile||isTablet)&&showAppPromoBanner&&!iPhone">
    <div id="mybpostleft">
      <div id="mybpostclose" (click)="closeAppPromoBanner()"></div>
      <div id="mybposticon"></div>
    </div>
    <div id="mybpostcenter">
      <span>{{'my_bpost.downloadMyBpostKey' | translate}}</span>
      <br>
      <span>{{'my_bpost.appName' | translate}}</span>
      <br>
      <span *ngIf="android">{{'my_bpost.androidOSKey' | translate}}</span>
      <!-- <span *ngIf="iPhone">{{'my_bpost.iOSKey' | translate}}</span> -->
      <span *ngIf="appStoreNameGeneric">{{'my_bpost.appStoreNameGeneric' | translate}}</span>
    </div>
    <div *ngIf="android" id="mybpostright">
      <a (click)="openApp()" target="_blank">{{'my_bpost.downloadButton' | translate}}</a>
    </div>
  </div>
  <div class="custom-red-border">

    <a id="goHomePage" (click)="goHome()"><img class="mobile-header" [ngClass]="{'lmg_logo': module === 'LMG'}" src="{{logoPath}}"></a>

    <div class="dropdown pull-right" [ngClass]="{'open' : toggle==true}">
      <a aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" class="dropdown-toggle" href="#" (click)="toggle=!toggle">
        <span id="selected">{{'language_header.lang_short' | translate }}</span>
        <span class="custom-caret"></span>
      </a>
      <ul class="dropdown-menu">
        <li><a href="#" (click)="toggle=!toggle" (click)="useLanguage('nl')">NL</a></li>
        <li><a href="#" (click)="toggle=!toggle" (click)="useLanguage('fr')">FR</a></li>
        <li><a href="#" (click)="toggle=!toggle" (click)="useLanguage('en')">EN</a></li>
      </ul>
    </div>
  </div>

</div>
