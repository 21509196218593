import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';

@Component({
  selector: 'app-payment-feedback-modal',
  templateUrl: './payment-feedback-modal.component.html',
  styleUrls: ['./payment-feedback-modal.component.css']
})
export class PaymentFeedbackModalComponent implements OnInit {

  public itemCode: string;
  public paymentStatusText: string;
  public paymentInfoText: string;
  public buttonText: string;
  public showSingleButton: boolean;
  public buttonTextConfirm: string;
  public paymentStatus: string;
  private statusChanged: boolean;
  public paymentIntiate = false;
  constructor(
    public readonly dialogRef: MatDialogRef<PaymentFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: PaymentFeedback,
    public readonly translateService: TranslateService,
    public itemsService: ItemsService,
    private readonly googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.itemCode = this.data.itemCode;
    this.showSingleButton = this.data.showSingleButton;
    this.paymentStatus = this.data.paymentStatus;
    this.statusChanged = this.data.statusChanged;
    this.setModalText();
  }

  getIconClass(): string {
    let iconClass = 'failed';
    if (this.statusChanged) {
      iconClass = 'success';
    }
    return iconClass;
  }

  closeModal(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }

  displayRefusalFeedback() {
    this.googleAnalyticsService.pushEventOnButtonClick('refuse_shipment_confirm');
    this.itemsService.refusePayment(this.itemCode, this.data.itemCreationDate).subscribe(
      data => {
        if (data.error) {
          this.paymentStatus = 'ERROR';
        } else {
          this.paymentStatus = data.paymentStatus;
          this.statusChanged = data.statusChanged;
        }
        this.showSingleButton = true;
        this.setModalText();
      });
  }

  setModalText() {
    if (this.paymentStatus === 'ERROR') {
      this.paymentStatusText = this.translateService.instant('feedback_modal.status.' + (this.data.calledStatus === 'REFUSED' ? 'refusalFailed' : 'failed'));
      this.paymentInfoText = this.translateService.instant('feedback_modal.info.' + (this.data.calledStatus === 'REFUSED' ? 'refusalFailed' : 'failed'));
      this.buttonTextConfirm = this.translateService.instant('feedback_modal.button.retry');
    } else if (this.data.calledStatus === 'GENERATE_RECEIPT') {
      this.paymentStatusText = this.translateService.instant('feedback_modal.status.failed');
      this.paymentInfoText = this.translateService.instant('feedback_modal.info.failed');
    } else if (this.data.calledStatus === 'REFUSED') {
      if (this.paymentStatus === 'INPROCESS' || this.paymentStatus === 'FAILED') {
        this.paymentStatusText = this.translateService.instant('feedback_modal.status.inProcessAndRefused');
        this.paymentInfoText = this.translateService.instant('feedback_modal.info.inProcessAndRefused');
      } else if ((this.paymentStatus === 'PAID' || this.paymentStatus === 'REFUSED' || this.paymentStatus === 'EXPIRED') && !this.statusChanged) {
        this.paymentStatusText = this.translateService.instant('feedback_modal.status.noAwaitingAndRefused');
        this.paymentInfoText = this.translateService.instant('feedback_modal.info.noAwaitingAndRefused');
      } else {
        if (!this.showSingleButton) {
          this.paymentStatusText = this.translateService.instant('feedback_modal.status.refuse_confirmation');
          this.paymentInfoText = this.translateService.instant('feedback_modal.info.refuse_confirmation');
          this.buttonTextConfirm = this.translateService.instant('feedback_modal.button.confirm');
        } else {
          this.paymentStatusText = this.translateService.instant('feedback_modal.status.refused');
          this.paymentInfoText = this.translateService.instant('feedback_modal.info.refused');
        }
      }
    } else if ((this.paymentStatus === 'PAID' || this.paymentStatus === 'REFUSED' || this.paymentStatus === 'EXPIRED')
      && !this.statusChanged && ['FINALIZE', 'INITIATE'].includes(this.data.calledStatus)) {
      this.paymentStatusText = this.translateService.instant('feedback_modal.status.noAwaitingAndPay');
      this.paymentInfoText = this.translateService.instant('feedback_modal.info.noAwaitingAndPay');
    } else if (this.paymentStatus === 'PAID') {
      this.paymentStatusText = this.translateService.instant('feedback_modal.status.success');
      this.paymentInfoText = this.translateService.instant('feedback_modal.info.success');
    } else if (this.paymentStatus === 'INPROCESS' || (this.paymentStatus === 'FAILED' && this.data.calledStatus === 'INITIATE')) {
      this.paymentStatusText = this.translateService.instant('feedback_modal.status.inProcess');
      this.paymentInfoText = this.translateService.instant('feedback_modal.info.inProcess');
    } else if (this.paymentStatus === 'INITIATE') {
      this.paymentIntiate = true;
      this.paymentStatusText = this.translateService.instant('feedback_modal.status.initiatePaymentWithoutConsent');
      this.paymentInfoText = this.translateService.instant('feedback_modal.info.initiatePaymentWithoutConsent');
      this.buttonTextConfirm = this.translateService.instant('feedback_modal.button.close');
    }
    this.buttonText = this.translateService.instant('feedback_modal.button.close');
  }
}


export interface PaymentFeedback {
  itemCode: string;
  paymentStatus: string;
  statusChanged: boolean;
  calledStatus: string;
  showSingleButton: boolean;
  itemCreationDate: string;
}
