import { Component, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input()
  public comments: string;
  constructor(
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
  }
  expandIcon() {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.comment-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('comment', tabStatus);
    });
  }
}
