<!-- Loading spinner -->
<app-spinner [module]="module" [loading]="loading"></app-spinner>

<ng-container *ngIf="!loading">
  <app-message-banner></app-message-banner>
</ng-container>

<div *ngIf="!loading" id="content-container" class="content_homepage" style="display: block;">
  <div class="custom-header-bpost faq-header background hidden-xs">
    <div class="image-container">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 image-container-content">
            <div class="logo">
              <a (click)="goHome()"><img src="/assets/images/bpost-logo.png" /></a>
            </div>
            <div class="header-text">
              <h1 class="custom_hand_pointer">{{'language_header.track_your_shipment' | translate }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-header-bpost faq-header background visible-xs">
    <div class="image-container">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 image-container-content">
            <div class="logo">
              <!-- <a (click)="goHome()"><img src="/assets/images/logo.png" /></a> -->
            </div>
            <div class="header-text">
              <h1 class="hidden-xs custom_hand_pointer">
                {{'language_header.track_your_shipment' | translate }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container homepage_parent_div" style="margin-top:-32px !important">
    <app-search></app-search>
    <div id="content-container" class="content_homepage_faq_listing content-block" style="display: block;">
      <div class="col-sm-10 col-sm-offset-1 col-xs-12">
        <div class="row sub-content-block ng-scope custom-padding-faq-title">
          <div class="col-xs-12 col-sm-6">
            <h3> {{ 'FAQ.title' | translate }}</h3>
          </div>
          <div class="col-sm-6 hidden-xs" style="text-align: right; padding-top: 8px">
            <div>
              <a class="custom_hand_pointer" (click)="previousRouteGo()">
                <span>{{ 'FAQ.back' | translate }}</span>&nbsp;<span class="glyphicon glyphicon-chevron-right"></span>
              </a>
            </div>
          </div>
        </div>
        <div *ngFor="let faq of faqs;let j = index">
          <div class="row">
            <div class="wrapper faq-listing center-block">
              <div class="col-xs-12">
                <div class="faq-category-level1">
                  <div class="category-title custom-padding-faq-question panel-title">
                    <a role="button" (click)="customJs()" data-toggle="collapse" data-parent="#accordion" href="#item{{j}}" aria-expanded="false" aria-controls="collapse" class="collapsed">
                      <i class="more-less glyphicon glyphicon-plus"></i>
                      <div class="text" [innerHTML]=faq.cat_title></div>
                    </a>
                  </div>
                </div>


                <div id="item{{j}}" class="panel-collapse collapse category-spacing-custom" role="tabpanel">
                  <ng-container *ngIf="faq['faqs']?.length!=0">
                    <div *ngFor="let subCategory of faq['faqs']">
                      <div class="panel-body-title"></div>
                      <ul>
                        <li class="faq">
                          <a class="custom_hand_pointer" (click)="goToFAQ(subCategory.faq_id)">
                            <span [innerHTML]=subCategory.faq_title></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngFor="let category of faq['categories'];let i = index">
            <div class="row">
              <div class="wrapper faq-listing center-block">
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel panel-default">
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="col-xs-12">
                          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="panel panel-default">
                              <div class="panel-heading active" role="tab">
                                <h4 class="panel-title">
                                  <a role="button" (click)="customJs()" data-toggle="collapse" data-parent="#accordion" href="#openPanel{{i}}item{{j}}" aria-expanded="false" aria-controls="collapse" class="collapsed">
                                    <i class="more-less glyphicon glyphicon-plus"></i>
                                    <p class="panel-parent-div_1" [innerHTML]=category.cat_title></p>
                                  </a>
                                </h4>
                              </div>
                              <div id="openPanel{{i}}item{{j}}" class="panel-collapse collapse category-spacing-custom" role="tabpanel">
                                <ng-container *ngIf="category['categories']?.length!=0">
                                  <div *ngFor="let childCategory of category['categories'];let h = index">
                                    <div class="panel-heading active" role="tab" style="padding-left: 15px">
                                      <h4 class="panel-title">
                                        <a role="button">
                                          <p class="panel-parent-child-div_1" [innerHTML]=childCategory.cat_title></p>
                                        </a>
                                      </h4>
                                    </div>
                                    <div *ngFor="let subChildCategory of childCategory['faqs']">
                                      <ng-container>
                                        <div class="panel-body-title"></div>
                                        <ul>
                                          <li class="faq">
                                            <a class="custom_hand_pointer" (click)="goToFAQ(subChildCategory.faq_id)">
                                              <span [innerHTML]=subChildCategory.faq_title></span>
                                            </a>
                                          </li>
                                        </ul>
                                      </ng-container>
                                    </div>
                                  </div>
                                </ng-container>

                                <ng-container *ngIf="category['faqs']?.length!=0">
                                  <div *ngFor="let subCategory of category['faqs']">
                                    <div class="panel-body-title"></div>
                                    <ul>
                                      <li class="faq">
                                        <a class="custom_hand_pointer" (click)="goToFAQ(subCategory.faq_id)">
                                          <span [innerHTML]=subCategory.faq_title></span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="col-sm-10 col-sm-offset-1 col-xs-12">
      <div class="hidden-xs" style="text-align: right; padding-top: 8px">
        <div>
          <a class="custom_hand_pointer" (click)="previousRouteGo()">
            <span> {{ 'FAQ.back' | translate }}</span>&nbsp;<span class="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      </div>
      <div class="visible-xs" style="text-align: right; padding-top: 8px">
        <div>
          <a (click)="previousRouteGo()">
            <span> {{ 'FAQ.back' | translate }}</span>&nbsp;<span class="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
