import { Component, Input, OnInit } from '@angular/core';
import { Map, View } from 'ol';
import { defaults as defaultControls, ScaleLine, ZoomToExtent } from 'ol/control.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { fromLonLat } from 'ol/proj.js';
import { OSM } from 'ol/source.js';
import VectorSource from 'ol/source/Vector.js';
import { Icon, Style } from 'ol/style.js';
import { Subscription } from 'rxjs';
import { ItemsService } from '../core/services/items.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class OpenlayerMapComponent implements OnInit {
  public lastKnownLocation: any;
  public targetLocation: any;
  public singleItem: any;
  @Input()
  public mapData: any;
  dataSubscription: Subscription;
  map: Map;
  interval: string;
  result: any;
  constructor(private readonly itemService: ItemsService) { }

  ngOnInit() {
    if (this.mapData) {
      this.firstCall(this.mapData);
    } else {
      this.itemService.apiData().subscribe(data => {
        this.firstCall(data);
      });
    }
  }
  public firstCall(data) {
    if (data && data.itemOnRoundStatus) {
      this.lastKnownLocation = data.itemOnRoundStatus.lastKnownLocation[0];
      this.targetLocation = data.itemOnRoundStatus.targetLocation[0];
      const iconFeature1 = new Feature({
        geometry: new Point(fromLonLat([parseFloat(this.lastKnownLocation.long), parseFloat(this.lastKnownLocation.lat)])),
        name: 'lastknownlocation',
      });

      const iconFeature2 = new Feature({
        geometry: new Point(fromLonLat([parseFloat(this.targetLocation.long), parseFloat(this.targetLocation.lat)])),
        name: 'targertlocation'
      });

      iconFeature1.setStyle(new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'assets/images/map/lastknownlocation-pin.svg',
          scale: 0.8,
        })
      }));
      iconFeature2.setStyle(new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'assets/images/map/targertlocation-pin.svg',
          scale: 0.8,
        })
      }));

      const iconLayerSource = new VectorSource({
        features: [iconFeature1, iconFeature2]
      });
      const iconLayer = new VectorLayer({
        source: iconLayerSource
      });
      const centerloc = this.midpoint(this.lastKnownLocation, this.targetLocation);
      const zoomVal = this.defineZoom(this.lastKnownLocation.lat, this.lastKnownLocation.long, this.targetLocation.lat, this.targetLocation.long);
      document.querySelector('div#map').innerHTML = '';
      this.mapDetails(iconLayer, centerloc, zoomVal);
    }
  }
  public mapDetails(iconLayer, centerloc, zoomVal) {
    const spanExtent = document.createElement('span');
    spanExtent.innerHTML = '<img src="assets/images/map/re-center.png">';
    this.map = new Map({
      target: 'map',
      view: new View({
        center: fromLonLat([centerloc.longmid, centerloc.latmid]),
        zoom: zoomVal
      }),
      layers: [
        new TileLayer({
          source: new OSM({
            url: 'https://tile.openstreetmap.be/osmbe/{z}/{x}/{y}.png'
          }),
        }), iconLayer
      ],
      controls: defaultControls().extend([
        new ZoomToExtent({
          label: spanExtent,
          tipLabel: 'Re-center'
        }),
        new ScaleLine({
          units: 'metric',
          bar: true,
          minWidth: 60,
        }),
      ]),
    });
    const button = document.getElementsByClassName('ol-zoom-extent');

    button[0].addEventListener('click', this.backtocenter.bind(this, centerloc, zoomVal), false);

    const spanPlus = document.getElementsByClassName('ol-zoom-in');
    spanPlus[0].innerHTML = '<img src="assets/images/map/add.png">';
    const spanMinus = document.getElementsByClassName('ol-zoom-out');
    spanMinus[0].innerHTML = '<img src="assets/images/map/minus.png">';
  }
  public backtocenter(centerloc, zoom) {
    this.map.getView().setCenter(fromLonLat([centerloc.longmid, centerloc.latmid]));
    this.map.getView().setZoom(zoom);
  }
  public midpoint(cordinate1, cordinate2) {  // function to find midpoint of targetloc and lastloc
    const longmid = (parseFloat(cordinate1.long) + parseFloat(cordinate2.long)) / 2;
    const latmid = (parseFloat(cordinate1.lat) + parseFloat(cordinate2.lat)) / 2;
    return { longmid, latmid };
  }
  public defineZoom(lat1, lon1, lat2, lon2) {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; // to kms
    let zoom = 9;
    dist = parseFloat(dist.toFixed(2));
    switch (true) {
      case dist <= 0.5:
        zoom = 16;
        break;
      case dist > 0.5 && dist <= 1:
        zoom = 15;
        break;
      case dist > 1 && dist <= 2:
        zoom = 14;
        break;
      case dist > 2 && dist <= 3:
        zoom = 13;
        break;
      case dist > 3 && dist <= 5:
        zoom = 12;
        break;
      case dist > 5 && dist <= 14:
        zoom = 11;
        break;
      case dist > 14 && dist <= 20:
        zoom = 10;
        break;
    }
    return zoom;
  }
}
