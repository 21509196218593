<span *ngIf="loading" id="fullScreenSpinner">
  <div class="spinner" style="position: absolute;width: 0px;z-index: 2000000000;left: 50%;top: 50%;" aria-role="progressbar">
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-0-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(0deg) translate(50px, 0px); border-radius: 5px;"></div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-1-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(21deg) translate(50px, 0px); border-radius: 5px;"></div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-2-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(42deg) translate(50px, 0px); border-radius: 5px;"></div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-3-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(63deg) translate(50px, 0px); border-radius: 5px;"></div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-4-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(84deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-5-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(105deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-6-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(127deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-7-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(148deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-8-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(169deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-9-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(190deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-10-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(211deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-11-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(232deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-12-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(254deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-13-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(275deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-14-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(296deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-15-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(317deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
    <div style="position: absolute; top: -5px; opacity: 0.25; animation: 1s linear 0s infinite normal none running opacity-60-25-16-17;">
      <div style="position: absolute; width: 10px; height: 10px; background: rgb(90, 90, 95); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px; transform-origin: left center; transform: rotate(338deg) translate(50px, 0px); border-radius: 5px;"> </div>
    </div>
  </div>
</span>

<div *ngIf="loading" id="fullScreenSpinnerLogo" style="width: 100px; height: 100px; position: absolute; top:0; bottom: 0; left: 0; right: 0; margin: auto;" [ngStyle]="{'background': 'url(' + logoPath + ') no-repeat center'}"></div>
