<!-- Loading spinner -->
<app-spinner [module]="module" [loading]="loading"></app-spinner>

<!-- new message banner code -->
<app-message-banner></app-message-banner>

<div id="content-container" class="content_homepage" style="display: block;">

  <div class="custom-header-bpost faq-header background hidden-xs">
    <div class="image-container">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 image-container-content">
            <div class="logo">
              <a (click)="goHome()"><img src="/assets/images/bpost-logo.png" /></a>
            </div>
            <div class="header-text">
              <h1 class="custom_hand_pointer">{{'language_header.track_your_shipment' | translate }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-header-bpost faq-header background visible-xs">
    <div class="image-container">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 image-container-content">
            <div class="logo">
            </div>
            <div class="header-text">
              <h1 class="hidden-xs">{{'language_header.track_your_shipment' | translate }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container homepage_parent_div" style="margin-top:-32px !important">
    <app-search></app-search>

    <div id="content-container" class="content_homepage" style="display: block;">
      <div id="content-container" class="content_homepage_faq_listing content-block" style="display: block;">
        <div class="col-sm-10 col-sm-offset-1 col-xs-12">
          <div class="row sub-content-block ng-scope">
            <div class="col-xs-12 col-sm-6 custom-title-color">
              <h3>{{ 'FAQ.title' | translate }}</h3>
            </div>
            <div class="col-sm-6 hidden-xs" style="text-align: right; padding-top: 8px">
              <div *ngIf="!goToHome">
                <a [hidden]="origin==undefined" class="custom_hand_pointer" (click)="goBack()">
                  <span *ngIf="backToFaq==true">{{ 'FAQ.backToList' | translate }}</span>
                  <span *ngIf="backToShipmentDetails==true">{{ 'FAQ.backToDetails' | translate }}</span>&nbsp;<span class="glyphicon glyphicon-chevron-right"></span></a>
              </div>
              <div *ngIf="goToHome">
                <a class="custom_hand_pointer" (click)="goHome()">
                  <span>{{ 'FAQ.back' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
          <h2 *ngIf="showFaqErrorBanner" class="custom-padding-title faq-loading-error">
            {{ 'FAQ.loadingError' | translate }}
          </h2>
          <!-- <div *ngIf="!showFaqErrorBanner"> -->
          <h2 *ngIf="!showFaqErrorBanner" class="custom-padding-title">
            <div [innerHtml]="questions"></div>
          </h2>
          <div class="custom-answer-color-black" id="externalContainer"></div>
          <!--EN-->
          <ng-container *ngIf="language=='en' && !showFaqErrorBanner">
            <div class="usabilla" appBpUsabilla ub-in-page="5b8f96e44667904c5b7d30ad"></div>
          </ng-container>
          <!--FR-->
          <ng-container *ngIf="language=='fr' && !showFaqErrorBanner">
            <div class="usabilla" appBpUsabilla ub-in-page="5b8e59e346679013030fdfee"></div>
          </ng-container>
          <!--NL-->
          <ng-container *ngIf="language=='nl' && !showFaqErrorBanner">
            <div class="usabilla" appBpUsabilla ub-in-page="5b8e5cb3466790241e571205"></div>
          </ng-container>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="col-sm-10 col-sm-offset-1 col-xs-12">
      <div class="hidden-xs" style="text-align: right; padding-top: 8px">
        <div *ngIf="!goToHome">
          <a [hidden]="origin==undefined" class="custom_hand_pointer" (click)="goBack()">
            <span *ngIf="backToFaq==true">{{ 'FAQ.backToList' | translate }}</span>
            <span *ngIf="backToShipmentDetails==true">{{ 'FAQ.backToDetails' | translate }}</span>&nbsp;<span class="glyphicon glyphicon-chevron-right"></span></a>
        </div>
        <div *ngIf="goToHome">
          <a class="custom_hand_pointer" (click)="goHome()">
            <span>{{ 'FAQ.back' | translate }}</span>
          </a>
        </div>
      </div>
      <div class="visible-xs" style="text-align: right; padding-top: 8px">
        <div *ngIf="!goToHome">
          <a [hidden]="origin==undefined" class="custom_hand_pointer" (click)="goBack()">
            <span *ngIf="backToFaq==true">{{ 'FAQ.backToList' | translate }}</span>
            <span *ngIf="backToShipmentDetails==true">{{ 'FAQ.backToDetails' | translate }}</span>&nbsp;<span class="glyphicon glyphicon-chevron-right"></span></a>
        </div>
        <div *ngIf="goToHome">
          <a class="custom_hand_pointer" (click)="goHome()">
            <span>{{ 'FAQ.back' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
