<div class="footer container">
  <div class="general-links-footer secondary-link-group">
    <div class="row hidden-xs">
      <div class="col-xs-12">
        <hr>
      </div>
    </div>
    <!-- Footer links mobile-->
    <div class="row hidden-xs">
      <div class="col-xs-12 general-links">
        <span>{{'footer.copyright' | translate:{year: year} }}</span> &nbsp;|&nbsp;
        <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.customer_service_link' | translate }}">{{'footer.customer_service' | translate }}</a> &nbsp;|&nbsp;
        <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.disclaimer_link' | translate }}">{{'footer.disclaimer' | translate }}</a> &nbsp;|&nbsp;
        <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.terms_conditions_link' | translate }}">{{'footer.terms_conditions' | translate }}</a>&nbsp;|&nbsp;
        <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.privacy_link' | translate }}">{{'footer.privacy' | translate }}</a>&nbsp;|&nbsp;
        <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.cookies_link' | translate }}">{{'footer.cookies'| translate}}</a>
      </div>
    </div>

    <!-- Footer links desktop-->
    <div class="row hidden-sm hidden-md hidden-lg general-links-footer-list">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-10 links-align-center">
            <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.customer_service_link' | translate }}">{{'footer.customer_service' | translate }}</a>
          </div>
          <div class="col-xs-2 chevron">
            <span class="visible-xs glyphicon glyphicon-chevron-right"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-10 links-align-center">
            <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.disclaimer_link' | translate }}">{{'footer.disclaimer' | translate }}</a>
          </div>
          <div class="col-xs-2 chevron">
            <span class="visible-xs glyphicon glyphicon-chevron-right"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-10 links-align-center">
            <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.terms_conditions_link' | translate }}">{{'footer.terms_conditions' | translate }}</a>
          </div>
          <div class="col-xs-2 chevron">
            <span class="visible-xs glyphicon glyphicon-chevron-right"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-10 links-align-center">
            <a class="link-secondary" target="_blank" href="{{bpostBE}}{{'footer.cookies_link'| translate  }}">{{'footer.cookies' | translate}}</a>
          </div>
          <div class="col-xs-2 chevron">
            <span class="visible-xs glyphicon glyphicon-chevron-right"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row hidden-sm hidden-md hidden-lg">
      <div class="col-xs-12">
        <hr>
      </div>
    </div>
    <!-- Copyright -->
    <div class="row hidden-sm hidden-md hidden-lg">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-10 links-align-center" style="padding-top: 5px">
            <span>{{'footer.copyright' | translate:{year: year} }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
