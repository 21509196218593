import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../../core/services/address.service';
import { CommonService } from '../../../core/services/common.service';
import { ItemsService } from '../../../core/services/items.service';
import { AddressInvalidModalComponent } from '../../components/address-invalid-modal/address-invalid-modal.component';
@Component({
  selector: 'app-address-suggestion-modal',
  templateUrl: './address-suggestion-modal.component.html',
  styleUrls: ['./address-suggestion-modal.component.css']
})
export class AddressSuggestionModalComponent implements OnInit {
  public note;
  public link;
  public dialogData;
  public showRadioButton = false;
  public buttonEnable = true;
  public addressSuggestions;
  constructor(
    public readonly dialogRef: MatDialogRef<AddressSuggestionModalComponent>,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public readonly data,
    public readonly translateService: TranslateService,
    public itemsService: ItemsService,
    public commonService: CommonService,
    public addressService: AddressService) { }
  form = new FormGroup({
    suggestion: new FormControl('', Validators.required)
  });
  ngOnInit() {
    this.note = this.translateService.instant('address_suggestion.note');
    this.link = this.translateService.instant('address_suggestion.link');
    const orgAdd = JSON.parse((localStorage.getItem('currentAddress')));
    this.addressSuggestions = this.data.addressSuggestions;
    const length = this.addressSuggestions.length;
    if (length > 0 && length <= 3) {
      this.showRadioButton = true;
    }
    for (let i = 0; i < length; i++) {
      this.addressSuggestions[i].postalCode = this.addressSuggestions[i].postalCode ? this.addressSuggestions[i].postalCode : orgAdd.postalcode;
      this.addressSuggestions[i].municipality = this.addressSuggestions[i].municipality ? this.addressSuggestions[i].municipality : orgAdd.city;
    }
  }

  getAddressInvalidDialog() {
    const dialogData = {
      productCategoryName: this.data.productCategoryName,
      itemIdentifier: this.data.itemIdentifier
    };
    this.dialog.open(AddressInvalidModalComponent, {
      width: '584px',
      data: dialogData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
    this.dialogRef.close();
  }
  closeModal(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }

  onChangeSelect(check, event): void {
    if (check === 1) { this.buttonEnable = event.type === 'change' ? false : true; }
    if (check === 2) { this.buttonEnable = event === '' ? true : false; }
  }

  submit() {
    const selectedValue = this.form.value.suggestion;
    const existingValues = JSON.parse(localStorage.getItem('updatedAddress'));
    console.log('selectedValue:', selectedValue);
    const params = {
      itemIdentifier: existingValues.itemIdentifier,     // to update with actual barcode
      name: existingValues.name,
      municipality: selectedValue.municipality,
      postalCode: selectedValue.postalCode,
      streetNumber: selectedValue.streetNumber,
      streetName: selectedValue.streetName,
      boxNumber: selectedValue.box,
      productCategoryName: this.data.productCategoryName
    };
    this.addressService.onClickChangeAddress(params);
    this.dialogRef.close();
  }
}
