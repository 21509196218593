import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../core/services/common.service';
import { FaqService } from '../core/services/faq.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { LoaderService } from '../core/services/loader.service';
import { SharedService } from '../core/services/shared.service';
import { TnTModule } from '../shared/enums/tnt-module';

declare var $: JQueryStatic;

@Component({
  selector: 'app-btr-faqlist-new',
  templateUrl: './btr-faqlist-new.component.html',
  styleUrls: ['./btr-faqlist-new.component.css']
})
export class BtrFaqlistNewComponent implements OnInit {
  public module = TnTModule.BTR;
  public language: string;
  public loading = false;
  public faqs = [];


  constructor(
    public readonly router: Router,
    public readonly activeRoute: ActivatedRoute,
    public readonly faqService: FaqService,
    private readonly commonService: CommonService,
    private readonly loader: LoaderService,
    private readonly sharedService: SharedService,
    private readonly translateService: TranslateService,
    private readonly googleAnalyticsService: GoogleAnalyticsService) { }


  ngOnInit() {
    this.router.navigate(['faqs'], {
      queryParams: { lang: localStorage.getItem('lang') }
    });

    this.activeRoute.queryParams.subscribe(queryParams => {
      this.loading = true;
      this.loader.show();
      this.language = localStorage.getItem('lang');
      if (queryParams && queryParams.lang) {
        this.language = queryParams.lang.toLowerCase();
        this.commonService.setLocalStorage(this.language);
        this.translateService.use(this.language);
      }
      this.googleAnalyticsService.pushVirtualPageViewEvent(this.language);
      this.faqService.getNewFAQ(this.language).subscribe(res => {
        this.faqs = res;
        this.loading = false;
        this.loader.hide();
      });
    });
  }

  public customJs() {
    $('.wrapper.faq-listing').on('click', '.panel-title', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
    });
  }

  public goHome() {
    this.commonService.backToHome(this.module);
  }

  public previousRouteGo() {
    this.sharedService.getPreviousURL().subscribe(messaage => {
      if (messaage) {
        let componentList = messaage.split('?');
        const qParams = {};
        for (const i of componentList[1].split('&')) {
          const j = i.split('=');
          qParams[j[0]] = j[1];
        }
        componentList = componentList[0].split('/');
        const component = componentList[componentList.length - 1];
        this.router.navigate([component], { queryParams: qParams });
      } else {
        this.commonService.backToHome(this.module);
      }
    });
  }

  public goToFAQ(faqId) {
    this.router.navigate(['faq'], {
      queryParams: { lang: localStorage.getItem('lang'), faqId },
      state: { origin: 'faqs' }
    });
  }

}
