<!-- Loading spinner -->
<app-spinner [module]="module" [loading]="loading"></app-spinner>

<!-- Header desktop view-->
<ng-container *ngIf="!loading">
  <div *ngFor="let eachItem of singleItem; index as i">
    <div class="custom-header-bpost background hidden-xs">
      <div class="container">
        <div class="custom_breadcrumb_two">
          <a (click)="goHome()"><img src="{{logoPath}}"
                 [ngClass]="{'logo_header' : module === 'BTR','lmg_logo' : module === 'LMG'}"></a>
          <ol class="breadcrumb">
            <li class="breadcrumb-item Track-parcel custom_hand_pointer">
              <a (click)="goHome()">{{'Items_summary.track_parcel' | translate}}</a>
            </li>
            <li class="breadcrumb-item active parcel-number">{{eachItem.searchCode}}</li>
          </ol>
        </div>
      </div>
    </div>

    <div>
      <app-message-banner *ngIf="module==='BTR'"></app-message-banner>
    </div>

    <div class="custom-header-bpost background visible-xs">
      <div class="container">
        <div class="custom_breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item Track-parcel custom_hand_pointer">
              <a (click)="goHome()">{{'Items_summary.track_parcel' | translate}}</a>
            </li>
            <li class="breadcrumb-item active parcel-number">{{eachItem.searchCode}}</li>
          </ol>
        </div>
      </div>
    </div>

    <div *ngIf="eachItem.searchOnCustRef" class="info-text">
      <div class="row">
        <div class="col-xs-12 ref-info">
          <div class="glyphicon glyphicon-info-sign"></div>
          <p class="ref-info-text">
            {{'Items_summary.cust_ref_info' | translate}}
          </p>
        </div>
      </div>
    </div>

    <!-- TRA-560: To show info text in minimized view -->
    <div *ngIf="isVisibleMinimizedViewWarning" class="info-text">
      <div class="row">
        <div class="col-xs-12 ref-info">
          <div class="glyphicon glyphicon-info-sign"></div>
          <p class="ref-info-text">
            {{'Items_summary.minimized_detail_info' | translate}}
          </p>
        </div>
      </div>
    </div>

    <!-- Back to summary -->
    <div *ngIf="viewMode==='SUMMARY' || eachItem.operationalMulticolliId">
      <div class="container">
        <!-- Desktop view -->
        <div class="row hidden-xs custom-multiple-parcel">
          <div class="col-xs-8">
            <p class="parceln"></p>
          </div>
          <div class="col-xs-4 pull-right custom-view-detail-a-parent back-to-summary-spacing">
            <a *ngIf="!eachItem.operationalMulticolliId" (click)="backToSummary()"
               class="custom_hand_pointer custom-view-detail-a">{{'Items_summary.back_to_summary' | translate}}</a>
            <a *ngIf="eachItem.operationalMulticolliId" (click)="backToSummary()"
               class="custom_hand_pointer custom-view-detail-a">{{'Items_summary.multicolli_text' | translate}}</a>
          </div>
        </div>
        <!-- Mobile view -->
        <div class="row visible-xs custom-multiple-parcel">
          <div class="col-xs-8">
            <p class="parceln"></p>
          </div>
          <div class="col-xs-6 pull-right custom-view-detail-a-parent back-to-summary-spacing-mobile">
            <a *ngIf="!eachItem.operationalMulticolliId" (click)="backToSummary()"
               class="custom_hand_pointer custom-view-detail-a">{{'Items_summary.back_to_summary' | translate}}</a>
            <a *ngIf="eachItem.operationalMulticolliId" (click)="backToSummary()"
               class="custom_hand_pointer custom-view-detail-a">{{'Items_summary.multicolli_text' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Additional image on delivered status -->
    <ng-container *ngIf="eachItem.isDeliveredStatusActive">
      <!-- Desktop view - Show map image -->
      <div class="hidden-xs">
        <div class="staticbackground-image"></div>
      </div>
      <!-- Mobile view - Show show map image if no safeplace image is available (safeplace image is shown via key-info-zone) -->
      <div class="visible-xs">
        <ng-container *ngIf="eachItem.safePlaceImgToShow === 'nosafeplace_imgtoshow'">
          <div class="staticbackground-image"></div>
        </ng-container>
      </div>
    </ng-container>

    <!-- Key info zone -->
    <div [ngClass]="{'chatbot-bpost-body': eachItem.isDeliveredStatusActive}">
      <app-key-info-zone [module]="module" [eachItem]="eachItem"
                         [hideStatusImageMobile]="eachItem.isDeliveredStatusActive"></app-key-info-zone>
    </div>

    <div *ngIf="isVisibleUntrackedInfo" class="container untracked-info-container">
      <div class="untracked-info-box">
        <div>
          <span class="untracked-info-title">{{'Items_summary.untracked_info_title' | translate}}</span>
        </div>
        <div class="untracked-info-text" [innerHTML]="'Items_summary.untracked_info_content' | translate ">
        </div>
      </div>
    </div>

    <!-- Details Section Start -->
    <div
         *ngIf="!isVisibleCustomerNote && !isVisibleDeliveryTo && !isVisibleDeliveryPref && !isVisiblePaymentDetails && !isVisibleSustainablePrefBlock && !isVisibleSustainableBlock; then left_right_merge else without_left_right_merge">
    </div>
    <ng-template #left_right_merge>
      <div class="container row secondary-info-zone-spacing">
        <!-- Left Side -->
        <div class="col-xs-12 col-md-6">
          <div *ngIf="isVisiblePaymentDetails">
            <app-payment-detail [singleItem]="eachItem" [isActivePaymentDetails]='isActivePaymentDetails'>
            </app-payment-detail>
          </div>
          <div>
            <app-parcel-details [parcelDetailsHeading]="eachItem.headings.parcel_details"
                                [moreInfoText]="eachItem.headings.more_info_about_parcel"></app-parcel-details>
          </div>
          <div *ngIf="isVisibleFAQ">
            <app-items-faqs [faqsHeading]="eachItem.headings.items_faq_heading"></app-items-faqs>
          </div>
          <div *ngIf="isVisibleComments">
            <app-comment [comments]="eachItem.comments"></app-comment>
          </div>
          <div *ngIf="isVisibleSustainablePrefBlock">
            <app-sustainability-pref></app-sustainability-pref>
          </div>
          <div *ngIf="isVisibleSustainableBlock">
            <app-sustainability-block></app-sustainability-block>
          </div>
        </div>
        <!-- Right Side -->
        <div class="col-xs-12 col-md-6">
          <div id="accordion" role="tablist" aria-multiselectable="true">
            <div *ngIf="isVisibleParcelImages">
              <app-parcel-images [parcelImageRefs]='eachItem.parcelImageReferences'
                                 [isAwaitingStatusActive]='isAwaitingStatusActive'></app-parcel-images>
            </div>
            <div *ngIf="isVisibleSignature">
              <app-signature></app-signature>
            </div>
            <div *ngIf="isVisibleTnTActivity">
              <app-tnt-activity [module]="module" [tntActivityText]="eachItem.headings.TNT_activity_sub_heading">
              </app-tnt-activity>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #without_left_right_merge>
      <div class="container row" style="margin: 0 auto;">
        <!-- Left Side -->
        <div class="col-xs-12 col-md-6">
          <div class="panel-group">
            <!-- For checking is not BE and partner name is present and not BTS-->
            <ng-container *ngIf="isVisibleCustomerNote">
              <div class="row customer-note-parent">
                <div class="col-xs-12">
                  <p class="content-customer-note" [innerHTML]="eachItem.helpTextKey"></p>
                </div>
              </div>
            </ng-container>
            <div *ngIf="isVisibleMapAndStops">
              <app-map [mapData]="mapData"></app-map>
            </div>
            <div *ngIf="isVisiblePaymentDetails">
              <app-payment-detail [singleItem]="eachItem" [isActivePaymentDetails]='isActivePaymentDetails'>
              </app-payment-detail>
            </div>
            <div *ngIf="isVisibleDeliveryTo">
              <app-delivery-address></app-delivery-address>
            </div>
            <div *ngIf="isVisibleSustainableBlock">
              <app-sustainability-block></app-sustainability-block>
            </div>
            <div *ngIf="isVisibleSignature">
              <app-signature></app-signature>
            </div>
            <div *ngIf="isVisibleDeliveryPref">
              <app-delivery-preferences></app-delivery-preferences>
            </div>
            <div *ngIf="!isVisibleDeliveryPref && isVisibleSustainablePrefBlock">
              <app-sustainability-pref></app-sustainability-pref>
            </div>
            <div *ngIf="isVisibleSFMBlock">
              <app-sign-for-me></app-sign-for-me>
            </div>
          </div>
        </div>
        <!-- Right Side -->
        <div class="col-xs-12 col-md-6">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div *ngIf="isVisibleParcelImages">
              <app-parcel-images [parcelImageRefs]='eachItem.parcelImageReferences'
                                 [isAwaitingStatusActive]='isAwaitingStatusActive'></app-parcel-images>
            </div>
            <div>
              <app-parcel-details [parcelDetailsHeading]="eachItem.headings.parcel_details"
                                  [moreInfoText]="eachItem.headings.more_info_about_parcel"></app-parcel-details>
            </div>
            <div *ngIf="isVisibleTnTActivity">
              <app-tnt-activity [module]="module" [tntActivityText]="eachItem.headings.TNT_activity_sub_heading">
              </app-tnt-activity>
            </div>
            <div *ngIf="isVisibleFAQ">
              <app-items-faqs [faqsHeading]="eachItem.headings.items_faq_heading"></app-items-faqs>
            </div>
            <div *ngIf="isVisibleComments">
              <app-comment [comments]="eachItem.comments"></app-comment>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Enabling Chat button -->
    <div id="chatbot2" style="right: 15px; bottom: 0px; opacity: 1; position:fixed; border:0px; z-index: 10;"></div>
    <ng-container *ngIf="eachItem.chatSupport ==='YES'">
      <div class="container hidden-xs">
        <div class="row">
          <button *ngIf="showButton" id="chat_with_support" class="open-button"
                  style="right: 0px; bottom: 0px; opacity: 1; position:fixed; border:0px; z-index: 10;"
                  (click)="openChatbot(eachItem.key.primaryKey)">
            <img src="/assets/images/fill-1.png" style="margin-top:10px" />
            {{'Items_summary.chat_with_support' | translate}}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="eachItem.chatSupport ==='YES'">
      <div class="container visible-xs">
        <div class="row">
          <ng-container *ngIf="showButton">
            <button id="chat_with_support" class="open-button-mobile"
                    style="right: 0px; bottom: 0px; opacity: 1; position:fixed; border:0px;z-index: 2; "
                    (click)="openChatbot(eachItem.key.primaryKey)">
              <img src="/assets/images/fill-1.png" style="margin-top:10px" />
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
