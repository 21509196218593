import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-btr-footer',
  templateUrl: './btr-footer.component.html',
  styleUrls: ['./btr-footer.component.css']
})
export class BtrFooterComponent implements OnInit {
  public bpostBE: string;
  public year: string;

  private releaseYear = 2015;

  constructor() { }

  ngOnInit() {
    this.bpostBE = environment.footerBaseURL;
    this.year = this.getCopyrightYear();
  }

  getCopyrightYear(): string {
    const currentYear = new Date().getFullYear();
    if (currentYear > this.releaseYear) {
      return `${this.releaseYear}-${currentYear}`;
    } else {
      return `${this.releaseYear}`;
    }
  }
}
