import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';

@Component({
  selector: 'app-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrls: ['./parcel-details.component.css']
})
export class ParcelDetailsComponent implements OnInit {
  @Input() parcelDetailsHeading;
  @Input() moreInfoText;
  singleItem;
  item;
  translations;
  senderCountry;
  receiverCountry;
  shippingMode;
  inNetworkDate;
  expectedDeliveryDate;
  senderAddress;
  receiverAddress;
  dynalogicText;
  partnerName;
  trackandtrace;
  isBpack = true;

  constructor(
    private readonly itemsService: ItemsService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.translations = JSON.parse(localStorage.getItem('translations_' + localStorage.getItem('lang')));
    this.item = this.singleItem[0];
    if (this.item.sender && this.item.sender.countryCode) { this.senderCountry = this.translations.countries[this.item.sender.countryCode]; }
    if (this.item.receiver && this.item.receiver.countryCode) { this.receiverCountry = this.translations.countries[this.item.receiver.countryCode]; }
    this.shippingMode = this.getModeOfShipping(this.item, this.translations);
    const inNetworkDateLocale = moment(new Date(this.item.inNetworkDate));
    this.inNetworkDate = inNetworkDateLocale.locale(localStorage.getItem('lang')).format('D MMMM YYYY');
    if (this.item.expectedDeliveryTimeRange) {
      const expectedDeliveryDateLocale = moment(new Date(this.item.expectedDeliveryTimeRange.day));
      this.expectedDeliveryDate = expectedDeliveryDateLocale.locale(localStorage.getItem('lang')).format('D MMMM YYYY');
    }
    if (this.item.sender && Object.keys(this.item.sender).length !== 0) { this.senderAddress = this.fullAddress(this.item.sender, this.senderCountry); }
    if (this.item.receiver && Object.keys(this.item.receiver).length !== 0) { this.receiverAddress = this.fullAddress(this.item.receiver, this.receiverCountry); }
    this.dynalogicText = this.translations.label.DynalogicTrackingUrl;
    this.trackandtrace = this.translations.label.TrackandTrace;
    this.partnerName = this.item.partnerName;
    this.isBpack = (this.item.partnerName !== 'Bpack') ? true : false;
  }
  expandIcon() {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.parcel-details-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('parcel_details', tabStatus);
    });
  }
  // Function to get addresses
  fullAddress(address, senderCountry) {
    let output = '';
    if (address.name) { output += address.name + ', '; }
    if (address.street) { output += address.street; }
    if (address.box && address.streetName && address.streetNumber) {
      output += ' / ' + address.box;
    }
    if (address.street) { output += ', '; }
    if (address.postcode) { output += address.postcode + ' '; }
    if (address.municipality) { output += address.municipality + ', '; }
    if (senderCountry) { output += senderCountry; }
    if (!senderCountry) { output = output.trim().replace(', ', '<sub>').replace(',', '').replace('<sub>', ', '); }
    output = output.toUpperCase();
    return output;
  }
  /** Get Mode of the shipping */
  getModeOfShipping(item, translations) {
    if (item.product && translations.service[item.product] && item.product !== '9999999999999') {
      return translations.service[item.product];
    }
    if (item.productName) {
      return item.productName;
    }
    if (sessionStorage.getItem('tntModule') === 'LMG') {
      return ' ';
    } else if (item.product) {
      return translations.service[item.product] ? translations.service[item.product] : ' ';
    } else {
      return translations.service[9999999999999];
    }
  }
}
