import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sustainability-block',
  templateUrl: './sustainabilityblock.component.html',
  styleUrls: ['./sustainabilityblock.component.css']
})
export class SustainabilityBlockComponent implements OnInit {

  singleItem;
  sustainabilityBlockTexts;
  language;
  imagePath;
  desc;
  title;

  constructor(
    private readonly traslateService: TranslateService
  ) { }

  ngOnInit() {
    this.language = localStorage.getItem('lang');
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    if (this.singleItem[0].sustainability && this.singleItem[0].sustainability.info.positive) {
      this.sustainabilityBlockTexts = this.singleItem[0].sustainability.info.positive[this.language.toUpperCase()];
      this.desc = this.sustainabilityBlockTexts.desc;
      this.title = this.sustainabilityBlockTexts.title;
      if (this.sustainabilityBlockTexts.image === 'questionmark') {
        this.imagePath = 'assets/images/sustainablity_leave_interogation.svg';
      }
      else if (this.sustainabilityBlockTexts.image === 'checkmark') {
        this.imagePath = 'assets/images/sustainability_leave_checkmark.svg';
      }
      else {
        // normal
        this.imagePath = 'assets/images/sustainability_warning.svg';
      }
    }
  }
}
