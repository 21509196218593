import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';

@Component({
  selector: 'app-parcel-images',
  templateUrl: './parcel-images.component.html',
  styleUrls: ['./parcel-images.component.css']
})
export class ParcelImagesComponent implements OnInit, AfterViewInit {

  @Input()
  public parcelImageRefs;
  @Input()
  public isAwaitingStatusActive;

  public singleItem: any;
  public rotationAmount = 0;

  constructor(
    private readonly itemService: ItemsService,
    private readonly commonService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  public imageObject = {};
  private readonly loadingImage = 'assets/images/1-Loading_bot-Med.gif';
  public showLoaderImage = true;

  ngOnInit() {
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.imageObject['img' + 0] = this.loadingImage;
    this.itemService.getParcelPicture(this.parcelImageRefs[0]).subscribe(data => {
      const image = 'data:image/jpeg;base64,' + data.base64String;
      this.imageObject['img' + 0] = image;
      $('#parcelImageCarousel .item').first().addClass('active');
      $('#parcelImageCarousel .carousel-indicators > li').first().addClass('active');
      this.showLoaderImage = false;
    });
  }

  ngAfterViewInit() {
    $('#parcelImageCarousel .item').first().addClass('active');
    $('#parcelImageCarousel .carousel-indicators > li').first().addClass('active');
  }

  expandIcon() {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.parcel-images-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('parcel-images', tabStatus);
    });
  }

  changeSlide(index) {
    if (!this.imageObject.hasOwnProperty('img' + index)) {
      this.showLoaderImage = true;
      this.imageObject['img' + index] = this.loadingImage;
      this.itemService.getParcelPicture(this.parcelImageRefs[index]).subscribe(data => {
        this.showLoaderImage = false;
        if (data.base64String) {
          const image = 'data:image/jpeg;base64,' + data.base64String;
          this.imageObject['img' + index] = image;
        } else {
          this.imageObject['img' + index] = null;
        }
      });
    }
  }

  openLightBox(x) {
    const i = this.imageObject['img' + x];
    $('#parcelImageCarouselModal .item.active').removeClass('active');
    $('#parcelImageCarouselModal .item').eq(x).addClass('active');
    ($('#imageModal') as any).modal('show');
  }

  onSwipe(event) {
    const type = event.type;
    if (type === 'swipeleft') {
      ($('#parcelImageCarousel') as any).carousel('next');
      this.changeSlide(($('#parcelImageCarousel .active') as any).index());
    }
    if (type === 'swiperight') {
      ($('#parcelImageCarousel') as any).carousel('prev');
      this.changeSlide(($('#parcelImageCarousel .active') as any).index());
    }
  }

  closeModal() {
    ($('#imageModal') as any).modal('hide');
  }

  getProductCategory(): string {
    return this.commonService.getProductCategoryName(this.singleItem[0]);
  }

  zoomImage(step) {
    const zoomStep = step === 'out' ? -50 : 50;
    const image = $('#parcelImageCarouselModal .item.active .img-responsive');
    image.width(image.width() + zoomStep);
    this.setImageContainerDimensions(this.rotationAmount);
  }

  rotateImage(direction) {
    const image1 = $('#parcelImageCarouselModal .item.active .img-responsive');
    image1.width(image1.width());
    this.rotationAmount += direction === 'left' ? -90 : 90;
    this.rotationAmount = this.rotationAmount < 0 ? (this.rotationAmount + 360) % 360 : this.rotationAmount % 360;
    const image = $('#parcelImageCarouselModal .item.active');
    const rotation = parseInt(image.attr('data-rotation'), 10);
    image.removeClass('rotate' + rotation);
    image.addClass('rotate' + this.rotationAmount);
    image.attr('data-rotation', this.rotationAmount);
    this.setImageContainerDimensions(this.rotationAmount);
  }

  setImageContainerDimensions(rotation) {
    const container = $('#parcelImageCarouselModal .item.active');
    const image = $('#parcelImageCarouselModal .item.active .img-responsive');
    if (rotation % 180 === 90) {
      container.width(image.height()).height(image.width());
    } else {
      container.width(image.width()).height(image.height());
    }
  }

  onCarousel(type) {
    if (type === 'prev') {
      ($('#parcelImageCarouselModal') as any).carousel('prev');
    } else {
      ($('#parcelImageCarouselModal') as any).carousel('next');
    }
    this.changeSlide(($('#parcelImageCarouselModal .active') as any).index());
  }
}
