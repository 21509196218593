<div class="sustainability-info">
  <div class="sustainability-column1">
    <img src="{{imagePath}}" class="sustainability-image" />
  </div>
  <div class="sustainability-column2">
    <p class="sustainability-label" [innerHTML]="title"></p>
    <p class="sustainability-content" [innerHTML]="desc"></p>
  </div>
</div>
<div class="pt20"></div>
