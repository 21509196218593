<ng-container *ngIf="homePageBannerStatus then homeBanner else Banner"></ng-container>

<ng-template #homeBanner>
  <div class="hidden-xs">
    <div class="row">
      <div>
        <div class="col-xs-12 notification-banner" *ngFor="let messageBanner of messageBanners">
          <!-- <i class="glyphicon glyphicon-remove pull-right custom_hand_pointer" (click)="closeMessageBanner(messageBanner.id)"></i> -->
          <img class="pull-right custom_hand_pointer" src="./assets/images/close.png" (click)="closeMessageBanner(messageBanner.id)">
          <b class="msgBannerTitle"> <img style="padding-right:4px; margin-left: 6px;" src="./assets/images/error.png"> {{ messageBanner.title }}</b>
          <p class="msgBannerbody" style="padding-left:33px; margin-left: 6px;" [innerHTML]="messageBanner.body | noSanitize: 'html'"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="visible-xs">
    <div class="row">
      <div>
        <div id="warning" class="notification-banner-mobile visible-xs" *ngFor="let messageBanner of messageBanners">
          <img class="pull-right custom_hand_pointer" style="padding-right: 10px;" src="{{imageSrc}}" data-toggle="collapse" data-target="#collapseBody" aria-expanded="false" aria-controls="collapseBody" (click)="toggleImg()">
          <p style="padding-right: 35px; font-size:18px; font-weight: 700;">{{ messageBanner.title }}
          </p>
          <div class="collapse" style="padding-right: 35px;" id="collapseBody">
            <p [innerHTML]="messageBanner.body | noSanitize: 'html'"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Banner>
  <div class="hidden-xs">
    <div class="col-xs-12 notification-banner" *ngFor="let messageBanner of messageBanners">
      <!-- <i class="glyphicon glyphicon-remove pull-right custom_hand_pointer" (click)="closeMessageBanner(messageBanner.id)"></i>-->
      <img class="pull-right custom_hand_pointer" src="./assets/images/close.png" (click)="closeMessageBanner(messageBanner.id)">
      <b class="msgBannerTitle"> <img style="padding-right:4px; margin-left: 6px;" src="./assets/images/error.png"> {{ messageBanner.title }}</b>
      <p class="msgBannerbody" style="padding-left:33px; margin-left: 6px;" [innerHTML]="messageBanner.body | noSanitize: 'html'"></p>
    </div>
  </div>
  <div class="visible-xs">
    <div id="warning" class="notification-banner-mobile " *ngFor="let messageBanner of messageBanners">
      <img class="pull-right custom_hand_pointer" style="padding-right: 10px;" src="{{imageSrc}}" data-toggle="collapse" data-target="#collapseBody" aria-expanded="false" aria-controls="collapseBody" (click)="toggleImg()">
      <p style="padding-right: 35px; font-size:18px; font-weight: 700;">{{ messageBanner.title }}
      </p>
      <div class="collapse" style="padding-right: 35px;" id="collapseBody">
        <p [innerHTML]="messageBanner.body | noSanitize: 'html'"></p>
      </div>
    </div>
  </div>
</ng-template>
