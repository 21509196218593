import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { CommonService } from '../core/services/common.service';
import { DrupalinformationService } from '../core/services/drupalinformation.service';
import { SharedService } from '../core/services/shared.service';

@Component({
  selector: 'app-btr-home',
  templateUrl: './btr-home.component.html',
  styleUrls: ['./btr-home.component.css']
})
export class BtrHomeComponent implements OnInit {
  information: any;
  validationMsg: any;
  public lang: string;
  postalCodeNotValid; itemCodeRequired; noResultsFound; generalizedError;
  errorItemCode; errorPostalCode;
  faqImageURL: any;
  bpostBE: any;
  trackBpostBE: any;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private commonService: CommonService,
    private informationdata: DrupalinformationService,
    private sharedService: SharedService,
    private translate: TranslateService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (typeof params.validationStack !== 'undefined' && params.validationStack !== '') {
        this.validationMsg = params.validationStack;

        if (this.validationMsg === 'NO_DATA_FOUND') {
          this.noResultsFound = true;
        }

        if (this.validationMsg === 'RECEIVER_POSTAL_CODE_NOT_VALID') {
          this.postalCodeNotValid = true;
          this.errorItemCode = params.itemCode;
          this.errorPostalCode = params.postalCode;
        }

        if (this.validationMsg !== 'RECEIVER_POSTAL_CODE_NOT_VALID'
          && this.validationMsg !== 'emptyResponse'
          && this.validationMsg !== undefined
          && this.validationMsg !== 'NO_DATA_FOUND') {
          this.generalizedError = true;
        }

      }
      if (params && params.lang) {
        this.lang = params.lang.toLowerCase();
        this.commonService.setLocalStorage(this.lang);
        this.translate.use(this.lang);
      }

      this.faqImageURL = environment.footerBaseURL + environment.barCodeImage;
      this.bpostBE = environment.drupalBaseURL;
      this.trackBpostBE = environment.trackBpostURL;
    });

    this.router.navigate(['home'], {
      queryParams: { lang: localStorage.getItem('lang') }
    });

    this.lang = localStorage.getItem('lang');
    $('.homepage-search-component .searchbox_float').removeClass('col-sm-offset-3').addClass('col-sm-offset-1');
  }

  dispalyBanner(value) {
    this.itemCodeRequired = value;
  }

  backToHome() {
    this.commonService.backToHome();
  }
  goToFAQs() {
    this.sharedService.clearPreviousURL();
    this.router.navigate(['faqs'], {
      queryParams: { lang: this.lang }
    });
  }

  ShowClick() {
    this.router.navigate(['faq'], {
      queryParams: { lang: this.lang, faqId: environment.faqId }
    });
  }
}
