<div class="custom-address-info row"
     *ngIf="singleItem[0].requestedDeliveryMethod==='HOME' && !singleItem[0].deliveryPoint && receiverAddress">
  <div class="col-xs-12 col-md-12">
    <div class="row">
      <div class="col-xs-12">
        <p class="deliver-to">{{'delivery_address.delivering_to' | translate}}</p>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-xs-12" *ngIf="singleItem[0].retourOrBackToSender && senderDetails">
                <p class="deliver-to-address">{{senderAddress}}
                </p>
            </div>
             <div class="col-xs-12"
                *ngIf="!singleItem[0].retourOrBackToSender && singleItem[0].activeStep && singleItem[0].activeStep.name !== 'return_to_sender' && receiverDetails.street!=undefined">
                <p class="deliver-to-address">{{receiverAddress}}</p>
            </div> -->
      <div class="col-xs-12" *ngIf="singleItem[0].activeStep && singleItem[0].activeStep.name">
        <p class="deliver-to-address">{{receiverAddress}}</p>
        <p *ngIf="this.visibilityFlag === 'success'">
          <span style="color:#404040"> {{'address_change_possible.text1' | translate}} </span>
          <span class="step-strong">
            <a id="btn" target="_blank" class="address-correction-link"
               (click)="callChangeAddress()">{{'address_change_possible.text2' | translate}} <img style="width: 18px; margin-bottom : 2px;"
                   src="/assets/images/chevron-right.png"></a>
          </span>
        </p>
        <p *ngIf="this.visibilityFlag === 'inprogress'">
          <span style="color:#404040"> {{'address_change_pending.text1' | translate}} </span>
        </p>
        <p *ngIf="this.visibilityFlag === 'changed'">
          <span style="color:#404040"> {{'address_change_executed.text1' | translate}} </span>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="custom-address-info row pickup-point-alignment" *ngIf="singleItem[0].deliveryPoint">
  <div class="col-xs-12 col-md-12" *ngIf="singleItem[0].activeStep && singleItem[0].activeStep.name
    && !singleItem[0].isDeliveredStatusActive">
    <div class="row" *ngIf="singleItem[0].deliveryPoint !==undefined">

      <!-- <div class="row"> -->
      <div class="col-xs-12">
        <p class="deliver-to">{{'pickup_point_details.pickup_point' | translate}}</p>
      </div>
      <!-- </div> -->
      <div class="col-md-6 col-xs-12">
        <div>
          <ng-container *ngIf="singleItem[0].deliveryPoint.type==='KARIBOO_POINT'">
            <p><strong class="pickup_point_deliver">{{ 'pickup_point_details.KARIBOO_POINT' | translate}}
              </strong><strong [innerHTML]=singleItem[0].pickupPointName></strong></p>
          </ng-container>
          <ng-container *ngIf="singleItem[0].deliveryPoint.type==='POSTAL_POINT'">
            <p><strong class="pickup_point_deliver">{{ 'pickup_point_details.POSTAL_POINT' | translate}}
              </strong><strong [innerHTML]=singleItem[0].pickupPointName></strong></p>
          </ng-container>
          <ng-container *ngIf="singleItem[0].deliveryPoint.type==='POSTAL_OFFICE'">
            <p><strong class="pickup_point_deliver">{{ 'pickup_point_details.POSTAL_OFFICE' | translate}}
              </strong><strong [innerHTML]=singleItem[0].pickupPointName></strong></p>
          </ng-container>
          <ng-container *ngIf="singleItem[0].deliveryPoint.type==='PARCEL_LOCKER'">
            <p><strong class="pickup_point_deliver">{{ 'pickup_point_details.PARCEL_LOCKER' | translate}}
              </strong><strong [innerHTML]=singleItem[0].pickupPointName></strong></p>
          </ng-container>

          <ng-container *ngIf="singleItem[0].showPicupPointTitle==='YES'">
            <p> <strong class="pickup_point_deliver">{{ 'pickup_point_details.pickup_point' | translate}}
              </strong><strong [innerHTML]=singleItem[0].pickupPointName></strong> </p>
          </ng-container>
          <p>
            {{singleItem[0].pickupPointAddress}}
          </p>
        </div>
      </div>
    </div>

    <!-- Get Directions -->
    <div class="row pickup-point-details-spacing"
         *ngIf="singleItem[0].deliveryPoint !==undefined && singleItem[0].deliveryPoint.showMap">
      <div class="col-xs-12 col-md-12">
        <div>
          <img width="18" height="25" src="/assets/images/icon_direction.png" />
          <span class="pickup-point-details"
                (click)="openDirection(singleItem[0].deliveryPoint.latitude,singleItem[0].deliveryPoint.longitude)">{{'pickup_point_details.get_directions'
            | translate}}
            <!-- <i class="more-less glyphicon glyphicon-chevron-right"></i> -->
          </span>
        </div>
      </div>
    </div>
    <!-- Opening Hours -->
    <div class="row" *ngIf="singleItem[0].deliveryPoint !==undefined">
      <div class="col-xs-12 col-md-12">
        <div class="pickup-point-details-spacing">
          <img width="20" height="20" src="/assets/images/icon_clock.png" />
          <span class="pickup-point-details" (click)="isVisableHideHours()">{{'pickup_point_details.opening_hours' |
            translate}}
            <i *ngIf="isCssDownArrow" class="opening-hours-minus glyphicon glyphicon-plus"></i>
            <i *ngIf="!isCssDownArrow" class="opening-hours-minus glyphicon glyphicon-minus"></i>
          </span>
        </div>
        <ng-container *ngIf="isVisHidHours">
          <div class="opening-hours-body" *ngFor="let days of singleItem[0].deliveryPoint.openingSchedules">
            <table class="timings">
              <tbody>
                <tr class="active">
                  <td style="vertical-align: top">{{days.weekOfTheDay}}</td>
                  <td>
                    <ng-container *ngFor="let day of days.openingHours">
                      {{day.open}} - {{day.close}} &nbsp; &nbsp;
                    </ng-container>
                    <ng-container>
                  <td class="sundayAlignment" *ngIf='days.dayOfTheWeek==="SUNDAY" && days.openingHours==""'>
                    {{'pickup_point_details.Closed' | translate}} &nbsp; &nbsp;</td>
        </ng-container>
        </td>

        </tr>
        </tbody>
        </table>
      </div>
      </ng-container>
    </div>
  </div>

  <!-- Show MyBpost icon -->
  <div class="row pickup-point-details-spacing"
       *ngIf="singleItem[0].isMybpostAppLinkVisible !==undefined && singleItem[0].isMybpostAppLinkVisible === true">
    <div class=" col-xs-12 col-md-12">
      <div>
        <img width="18" height="25" src="/assets/images/MobileBpostApp.svg" />
        <span class="pickup-point-details">
          <a target="_blank" class="pickup-point-details myblink" href="{{'mybpost_lean_locker_text.link' | translate}}">
            {{'mybpost_lean_locker_text.text' | translate}}</a></span>
      </div>
    </div>
  </div>
</div>
</div>
