import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
import { AddressChangeModalComponent } from '../../src/app/shared/components/address-change-modal/address-change-modal.component';
import { AddressConfirmModalComponent } from '../../src/app/shared/components/address-confirm-modal/address-confirm-modal.component';
import { AddressDialogModalComponent } from '../../src/app/shared/components/address-dialog-modal/address-dialog-modal.component';
import { AddressInvalidModalComponent } from '../../src/app/shared/components/address-invalid-modal/address-invalid-modal.component';
import { AddressSuggestionModalComponent } from '../../src/app/shared/components/address-suggestion-modal/address-suggestion-modal.component';
import { DeliveryAddressComponent } from '../../src/app/shared/components/delivery-address/delivery-address.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BpUsabillaDirective } from './bp-usabilla.directive';
import { BtrFaqNewComponent } from './btr-faq-new/btr-faq-new.component';
import { BtrFaqComponent } from './btr-faq/btr-faq.component';
import { BtrFaqlistNewComponent } from './btr-faqlist-new/btr-faqlist-new.component';
import { BtrFaqlistComponent } from './btr-faqlist/btr-faqlist.component';
import { BtrFooterComponent } from './btr-footer/btr-footer.component';
import { BtrHeaderComponent } from './btr-header/btr-header.component';
import { BtrHomeComponent } from './btr-home/btr-home.component';
import { BtrLayoutComponent } from './btr-layout/btr-layout.component';
import { BtrSearchComponent } from './btr-search/btr-search.component';
import { CoreModule } from './core/core.module';
import { HttpIntercept } from './core/services/http-intercept.service';
import { ItemsService } from './core/services/items.service';
import { SharedModule } from './shared/shared.module';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides: any = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    // TODO: move shared components to shared/components
    BtrLayoutComponent,
    BtrHeaderComponent,
    BtrFooterComponent,
    BtrHomeComponent,
    BtrSearchComponent,
    BtrFaqlistComponent,
    BtrFaqComponent,
    BpUsabillaDirective,
    BtrFaqlistNewComponent,
    BtrFaqNewComponent

  ],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [CookieService, ItemsService, Title,
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true }, {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    }],
  bootstrap: [AppComponent],
  exports: [AddressChangeModalComponent,
    DeliveryAddressComponent, AddressDialogModalComponent, AddressSuggestionModalComponent, AddressInvalidModalComponent, AddressConfirmModalComponent]
})
export class AppModule { }
