import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';
import { SharedService } from '../../../core/services/shared.service';

@Component({
  selector: 'app-items-faqs',
  templateUrl: './items-faqs.component.html',
  styleUrls: ['./items-faqs.component.css']
})
export class ItemsFaqsComponent implements OnInit {
  @Input() faqsHeading;
  singleItem; faqs; joinedFaqs; faqsResponse; arrayFaq = []; language; arrayNewFaq; faqIds;
  constructor(
    private itemsService: ItemsService,
    private router: Router,
    private sharedService: SharedService,
    private googleAnalyticsService: GoogleAnalyticsService) { }
  commercialSenderName = ''; translations;
  ngOnInit() {

    this.language = localStorage.getItem('lang');
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.faqIds = this.singleItem[0].faqIds || [];

    if (this.singleItem[0].senderCommercialName) {
      this.commercialSenderName = this.singleItem[0].senderCommercialName;
    } else {
      this.translations = JSON.parse(localStorage.getItem('translations_' + localStorage.getItem('lang')));
      this.commercialSenderName = this.translations.label.ContactSender;
    }

    this.itemsService.getFaqsInItemsDetail(this.faqIds, this.language).subscribe(
      (data) => {
        this.arrayNewFaq = data;
      },
      (error) => { }
    );
  }
  expandIcon() {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.item-faq-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('items-faq', tabStatus);
    });
  }
  goToFAQs() {
    this.sharedService.clearPreviousURL();
    const URL = window.location.href;
    this.sharedService.setPreviousURL(URL);
    this.router.navigate(['faqs'], {
      queryParams: { lang: this.language }
    });

  }
  goToFAQDetail(faqId) {
    this.sharedService.clearPreviousURL();
    const URL = window.location.href;
    this.sharedService.setPreviousURL(URL);
    this.router.navigate(['faq'], {
      queryParams: { lang: this.language, faqId },
      state: { origin: 'shipmentDetails' }
    });
  }

}
