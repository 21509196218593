import { Component, OnInit } from '@angular/core';
import { TnTModule } from '../shared/enums/tnt-module';

@Component({
  selector: 'app-btr-header',
  templateUrl: './btr-header.component.html',
  styleUrls: ['./btr-header.component.css']
})
export class BtrHeaderComponent implements OnInit {

  public module = TnTModule.BTR;

  constructor() { }

  ngOnInit() {
  }

}
