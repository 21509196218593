<div class="wrapper center-block">
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title item-faq-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" data-parent="#accordion" href="#do_not_pay"
           aria-expanded="false" aria-controls="collapse" class="collapsed">
          <i class="more-less glyphicon glyphicon-plus"></i>
          <p class="panel-parent-div">{{faqsHeading}}</p>
          <p class="panel-sub-div">{{'items_FAQs.items_faq_sub_heading' | translate}}</p>
        </a>
      </h4>
    </div>
    <div id="do_not_pay" class="panel-collapse collapse custom-sub-faq faq-sub-category-padding-custom" role="tabpanel"
         aria-labelledby="headingOne">
      <div class="panel-body sub">

        <!-- <div class="panel-heading active" role="tab" id="headingSubOne" *ngFor="let faq of arrayFaq">
          <h4 class="panel-title">
            <a role="button" (click)="goToFAQDetail(faq[0])">
              <i class="more-less glyphicon glyphicon-chevron-right custom-faq-chevron"></i>
              <p class="panel-sub-div items-faqs-font">{{ faq[1].title }}</p>
            </a>
          </h4>
        </div> -->

        <!-- Added new block for testing and Will remove after testing all faq(top 3) things -->
        <div class="panel-heading active" role="tab" id="headingSubOne" *ngFor="let faq of arrayNewFaq">
          <h4 class="panel-title">
            <a role="button" (click)="goToFAQDetail(faq.faq_id)">
              <i class="more-less glyphicon glyphicon-chevron-right custom-faq-chevron"></i>
              <p class="panel-sub-div items-faqs-font" [innerHTML]="faq.faq_title | translate "></p>
            </a>
          </h4>
        </div>

        <div class="panel-heading active last" role="tab" id="headingSubOne">
          <h4 class="panel-title">
            <a role="button" (click)="goToFAQs()">
              <i class="more-less glyphicon glyphicon-chevron-right custom-faq-chevron"></i>
              <p class="panel-sub-div items-faqs-font">{{'items_FAQs.show_more_faqs' | translate}}</p>
            </a>
          </h4>
        </div>
        <div class="panel-sub-div">
          <p>{{'items_FAQs.more_information' | translate}} {{commercialSenderName}} </p>
        </div>
      </div>
    </div>
  </div>
</div>
