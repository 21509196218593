<div class="payment-feedback">
  <i class="icon" [ngClass]="getIconClass()" aria-hidden="true"></i>
  <div class="status">
    <span [innerHTML]=paymentStatusText></span>
  </div>
  <div class="info">
    <span [innerHTML]=paymentInfoText></span>
  </div>
  <div *ngIf="showSingleButton" class="custom-submit-button">
    <button id='closeBtn' (click)="(this.paymentStatus === 'INITIATE') ? closeModal(false, $event) :closeModal(true,$event)">{{buttonText}}</button>
  </div>
  <div *ngIf="!showSingleButton" class="row button_group">
    <button id='noBtn' class="Box_No" (click)="closeModal(true,$event)">{{buttonText}}</button>
    <button id='confirmBtn' class="Box_Yes" (click)="(this.data.calledStatus === 'INITIATE') ? closeModal(true, $event) :  displayRefusalFeedback()">{{buttonTextConfirm}}</button>
  </div>
</div>
