import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DrupalinformationService } from '../../../core/services/drupalinformation.service';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() wrongItemIdentifier;
  @Input() wrongPostalCode;
  public isNewSearchBar = true;
  public itemIdentifier: string;
  public postalCode: string;
  goToResults; itemCodeRequired; openModel = false;
  information: any;
  validationMsg: any;
  public lang: string;
  postalCodeNotValid; noResultsFound; generalizedError; untracked;
  errorItemCode; errorPostalCode;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private informationdata: DrupalinformationService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {

    // $(document).ready(function(){
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
    this.route.queryParams.subscribe(params => {
      if (typeof params.validationStack !== 'undefined' && params.validationStack !== '') {
        this.validationMsg = params.validationStack;

        if (this.validationMsg === 'NO_DATA_FOUND') {
          this.noResultsFound = true;
        }

        if (this.validationMsg === 'UNTRACKED_H') {
          this.untracked = true;
        }
        if (this.validationMsg === 'RECEIVER_POSTAL_CODE_NOT_VALID' || this.validationMsg === 'CUST_REF_POSTAL_CODE_NOT_VALID') {
          this.postalCodeNotValid = true;
          this.errorItemCode = params.itemCode;
          this.errorPostalCode = params.postalCode;
        }

        if (this.validationMsg !== 'RECEIVER_POSTAL_CODE_NOT_VALID'
          && this.validationMsg !== 'CUST_REF_POSTAL_CODE_NOT_VALID'
          && this.validationMsg !== 'emptyResponse'
          && this.validationMsg !== undefined
          && this.validationMsg !== 'NO_DATA_FOUND'
          && this.validationMsg !== 'UNTRACKED_H') {
          this.generalizedError = true;
        }
      }
    });

    this.itemIdentifier = this.wrongItemIdentifier;
    this.postalCode = this.wrongPostalCode;
    this.assessSearchBar();
  }

  assessSearchBar() {
    const url = this.router.url;
    const data = url.split('?')[0];
    if (!data.includes('/home')) {
      this.isNewSearchBar = false;
    }
  }

  getItems(itemIdentifier, postalCode) {
    if (itemIdentifier) {
      itemIdentifier = itemIdentifier.replace(/([\&\=\/\\]|^\s+|\s+$)/g, '');
      if (postalCode) {
        postalCode = postalCode.replace(/([\&\=\?\/\\]|^\s+|\s+$)/g, '');
      }
      sessionStorage.setItem('itemCode', itemIdentifier);
      sessionStorage.setItem('postalCode', postalCode);

      if (itemIdentifier === null || itemIdentifier === '' || itemIdentifier === undefined) {
        this.goToResults = false;
      } else {
        this.goToResults = true;
      }

      if (!postalCode) {
        postalCode = '';
      }

      if (this.goToResults === true) {
        this.googleAnalyticsService.pushEventOnFormSubmit(itemIdentifier, postalCode);
        if (postalCode !== '') {
          this.router.navigate(['search'], {
            queryParams: { lang: localStorage.getItem('lang'), itemCode: itemIdentifier, postalCode }
          });
        } else {
          this.router.navigate(['search'], {
            queryParams: { lang: localStorage.getItem('lang'), itemCode: itemIdentifier }
          });
        }

      } else {
        {
          this.itemCodeRequired = true;
          this.valueChange.emit(this.itemCodeRequired);
        }
      }
    }
  }
}
