<div class="container">
  <button type="button" class="xbutton close" style="color:#ef2637;" aria-hidden="true" (click)="closeModal(false, $event)">x</button>
  <div class="iconclass">
    <i class="icon home" aria-hidden="true"></i>
  </div>
  <div class="form-group">
    <h2 class="text-center">{{'confirm_address.title' | translate}}</h2>
    <h5 class="text-center">{{'confirm_address.note' | translate}}</h5>
    <h5 class="text-center address">{{addressToConfirm}}</h5>
  </div>
  <p class="text-center" [innerHtml]=" 'confirm_address.description' | translate"></p>
  <div class="form-group submit-button">
    <div class="myClass col-sm-6">
      <button id='closeBtn' type="button" class="Box_Close"
              (click)="goToBack()">{{'confirm_address.back' | translate}}
      </button>
    </div>
    <div class="col-sm-6">
      <button id='closeBtn' class="Box_Confirm" type="button" (click)="confirm()">{{'confirm_address.confirm' | translate}}</button>
    </div>
  </div>
</div>
