<!-- Loading spinner -->
<app-spinner [module]="module" [loading]="loading"></app-spinner>

<!-- Header desktop view -->
<div class="custom-header-bpost background hidden-xs" *ngIf="nrOfItems > 1">
    <div class="container">
        <div class="custom_breadcrumb_two">
            <a (click)="goHome()"><img class="logo_header" src="/assets/images/bpost-logo.png"></a>
            <ol class="breadcrumb">
                <li class="breadcrumb-item Track-parcel custom_hand_pointer"><a (click)="goHome()">{{'Items_summary.track_parcel' | translate}}</a></li>
                <li class="breadcrumb-item active parcel-number">{{itemIdentifier}}</li>
            </ol>
        </div>
    </div>
</div>

<!-- Header mobile view -->
<div *ngIf="nrOfItems > 1">
    <app-message-banner></app-message-banner>
</div>

<div class="custom-header-bpost background visible-xs" *ngIf="nrOfItems > 1">
    <div class="container">
        <div class="custom_breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item Track-parcel custom_hand_pointer"><a (click)="goHome()">{{'Items_summary.track_parcel' | translate}}</a></li>
                <li class="breadcrumb-item active parcel-number">{{itemIdentifier}}</li>
            </ol>
        </div>
    </div>
</div>

<!-- Content single item -->
<ng-container *ngIf="nrOfItems === 1">
    <div>
        <app-item-detail [module]="module" [viewMode]="viewMode" [singleItem]="filteredItems">loading....</app-item-detail>
    </div>
</ng-container>

<!-- Content multiple items -->
<ng-container *ngIf="nrOfItems > 1">
    <div>
        <app-search-summary [module]="module" [items]="items" [nrOfItemsToShow]="3">loading....</app-search-summary>
    </div>
</ng-container>