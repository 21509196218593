import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  headerFooter;
  headerFooterVisiblility: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  show() {
    this.headerFooter = false;
    this.headerFooterVisiblility.next(this.headerFooter);
  }
  hide() {
    this.headerFooter = true;
    this.headerFooterVisiblility.next(this.headerFooter);
  }
}
