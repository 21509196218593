<!-- <div class="col-xs-12 col-md-12">
    <div class="row"> -->
<div class="wrapper center-block">
  <!-- <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true"> -->
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title signature-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" data-parent="#accordion"
           href="#signature_detail" aria-expanded="false" aria-controls="collapse"
           class="collapsed">
          <i class="more-less glyphicon glyphicon-plus"></i>
          <p class="panel-parent-div">{{'signature.title' | translate }}</p>
          <p class="panel-sub-div">{{'signature.details' | translate }}</p>
        </a>
      </h4>
    </div>
    <div id="signature_detail" class="panel-collapse collapse" role="tabpanel"
         aria-labelledby="headingOne">
      <div class="panel-body">
        <p class="panel-sub-div" *ngIf="recaptchaStatus">{{'signature.confirm' | translate }}</p>
        <ng-container *ngIf="recaptchaStatus">
          <!-- <div><re-captcha (resolved)='resolved($event)' siteKey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"></re-captcha></div> -->
          <div>
            <re-captcha (resolved)='resolved($event)' siteKey={{recaptchaSiteKey}}></re-captcha>
          </div>
        </ng-container>
        <ng-container *ngIf="imageStatus">
          <div><img style="height: 250px" [src]="imageToShow"></div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
<!-- </div>
</div> -->
