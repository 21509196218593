import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import hash from 'object-hash';
import { DrupalinformationService } from '../../../core/services/drupalinformation.service';

@Component({
  selector: 'app-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.css']
})
export class MessageBannerComponent implements OnInit {
  public messageBanners = [];
  public imageSrc: string;
  public homePageBannerStatus = false;

  private readonly plusImgURL = './assets/images/add.png';
  private readonly minusImgURL = './assets/images/minus.png';
  private readonly productCategory = [
    'InboundEU',
    'InboundNonEU',
    'Outbound',
    'National'
  ];
  constructor(
    private informationBanner: DrupalinformationService,
    private route: ActivatedRoute,
    private router: Router) { }

  public ngOnInit(): void {
    this.imageSrc = this.plusImgURL;
    this.route.queryParams.subscribe(params => {
      this.messageBanners = [];
      if (this.router.url.includes('search')) {
        this.postalDrupalService();
      } else {
        this.callDrupalService();
      }
      if (this.router.url.includes('home')) {
        this.homePageBannerStatus = true;
      }
    });
  }

  public closeMessageBanner(messageBannerId: string): void {
    this.messageBanners = this.messageBanners.filter(messageBanner => messageBanner.id !== messageBannerId); // remove banner from message banners
    localStorage.setItem('hideMessageBanner-' + messageBannerId, 'true');
  }

  public toggleImg(): void {
    if (this.imageSrc === this.plusImgURL) {
      this.imageSrc = this.minusImgURL;
    } else {
      this.imageSrc = this.plusImgURL;
    }
  }

  private callDrupalService(): void {
    this.informationBanner.getInformation().subscribe(informationData => {
      // General messages is an array, but it should only contain a single item (but we support multiple as well)
      const generalMessageBanners = [];
      if (informationData.general_msg) {
        informationData.general_msg.forEach(element => {
          const messageBannerId = this.getMessageBannerId(element);
          const messageBannerTitle = element[`general_msg_banner_title_${localStorage.getItem('lang')}`];
          const messageBannerBody = element[`general_msg_banner_body_${localStorage.getItem('lang')}`];
          const hideMessageBanner = localStorage.getItem(`hideMessageBanner-${messageBannerId}`) ? true : false;
          if (messageBannerBody && !hideMessageBanner) { // only add when available (not empty) and not hidden
            generalMessageBanners.push({
              id: messageBannerId,
              title: messageBannerTitle,
              body: messageBannerBody
            });
          }
        });
      }
      this.messageBanners = generalMessageBanners;
    });
  }

  private postalDrupalService(): void {
    let itemsInfo: any;
    if (localStorage.getItem('SelectedItem')) {
      itemsInfo = JSON.parse(localStorage.getItem('SelectedItem'));
    }
    if (!itemsInfo && localStorage.getItem('MultipleItems')) {
      itemsInfo = JSON.parse(localStorage.getItem('MultipleItems'));
    }

    this.informationBanner.getInformation().subscribe(informationData => {
      // General messages is an array, but it should only contain a single item (but we support multiple as well)
      const generalMessageBanners = [];
      if (informationData.general_msg) {
        informationData.general_msg.forEach(element => {
          const messageBannerId = this.getMessageBannerId(element);
          const messageBannerTitle = element[`general_msg_banner_title_${localStorage.getItem('lang')}`];
          const messageBannerBody = element[`general_msg_banner_body_${localStorage.getItem('lang')}`];
          const hideMessageBanner = localStorage.getItem(`hideMessageBanner-${messageBannerId}`) ? true : false;
          if (messageBannerBody && !hideMessageBanner) { // only add when available (not empty) and not hidden
            generalMessageBanners.push({
              id: messageBannerId,
              title: messageBannerTitle,
              body: messageBannerBody
            });
          }
        });
      }

      // Mutiple conditional messages is an array which can contain multiple items
      if (informationData.conditional_msg) {
        informationData.conditional_msg.forEach(element => {
          const messageBannerId = this.getMessageBannerId(element);
          const messageBannerTitle = element[`conditional_msg_banner_title_${localStorage.getItem('lang')}`];
          const messageBannerBody = element[`conditional_msg_banner_body_${localStorage.getItem('lang')}`];
          const hideMessageBanner = localStorage.getItem(`hideMessageBanner-${messageBannerId}`) ? true : false;

          if (messageBannerBody && !hideMessageBanner) { // only add when available (not empty) and not hidden
            let pcAvailableStatus = true; // Postal code : pc
            let psAvailableStatus = true; // Parcel status : ps
            let accountidAvailableStatus = true;

            const pcMatchStatus = [];
            const psMatchStatus = [];
            const accountidMatchStatus = [];
            let ptMatchStatus = true; // product type : pt

            // Post code check
            if (element.pocode.trim()) {
              for (const item of itemsInfo) {
                if (element.pocode.includes(item.receiver.postcode)) {
                  pcMatchStatus.push(true);
                } else {
                  pcMatchStatus.push(false);
                }
              }
            } else {
              pcAvailableStatus = false;
            }

            // Account ID Check
            if (element.accountid.trim()) {
              for (const item of itemsInfo) {
                if (element.accountid.includes(item.customerAccountId)) {
                  accountidMatchStatus.push(true);
                } else {
                  accountidMatchStatus.push(false);
                }
              }
            } else {
              accountidAvailableStatus = false;
            }

            // Parcel Status check
            const parcelStatus = element.parcel_status;
            if (parcelStatus.hasOwnProperty('NONE')) {
              psAvailableStatus = false;
            } else {
              if (Object.keys(parcelStatus).length > 0) {
                for (const item of itemsInfo) {
                  for (const processStep of item.processOverview.processSteps) {
                    if (processStep.status === 'active') {
                      if (Object.keys(parcelStatus).filter(val => val === processStep.knownProcessStep).length === 1) {
                        psMatchStatus.push(true);
                      } else {
                        psMatchStatus.push(false);
                      }
                    }
                  }
                }
              } else {
                psAvailableStatus = false;
              }
            }

            // Product type check
            const productType = element.product_type;
            if (productType && this.productCategory.includes(productType)) {
              for (const item of itemsInfo) {
                if (productType !== item.shipmentType) {
                  ptMatchStatus = false;
                }
              }
            }
            const pcResult = pcMatchStatus.filter((val) => val === false);
            const psResult = psMatchStatus.filter((val) => val === false);
            const accountidMatchResult = accountidMatchStatus.filter((val) => val === false);

            // Determine if conditional messages must be shown
            let useConditionalMessage = true;

            if ((pcAvailableStatus && pcResult.length > 0)) {
              useConditionalMessage = false;
            }
            if ((psAvailableStatus && psResult.length > 0)) {
              useConditionalMessage = false;
            }
            if ((accountidAvailableStatus && accountidMatchResult.length > 0)) {
              useConditionalMessage = false;
            }
            if (!ptMatchStatus) {
              useConditionalMessage = false;
            }

            if (useConditionalMessage) {
              this.messageBanners.push({
                id: messageBannerId,
                title: messageBannerTitle,
                body: messageBannerBody
              });
            }
          }
        });
      }

      // Fallback to general messages in case no conditional message are present
      if (this.messageBanners.length === 0 && generalMessageBanners) {
        this.messageBanners = generalMessageBanners;
      }
    });
  }

  private getMessageBannerId(element: any): string {
    return hash(element);
  }
}
