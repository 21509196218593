<!-- <div class="container"> -->
<div class="row">
  <div class="col-md-12">
    <div class="privacy-popup-rectange center" *ngIf="privacyModal" cdkTrapFocus>
      <img src="/assets/images/privacy.svg" class="privacy-popup-image center">
      <div class="center">
        <p class="Privacy-terms center">{{'privacy_popup.privacy_terms' | translate}}</p>
      </div>
      <div class="center">
        <p class="By-selecting-Yes-I center">{{'privacy_popup.privacy_text' | translate}}</p>
      </div>
      <div class="privacyLinkRectange">
        <p class="By-selecting-Yes-I">{{'privacy_popup.link_text' | translate}} <br> <a
             href="{{bpostBE}}{{'privacy_popup.privacy_link1' | translate}}" target="_blank" class="Text">
            {{'privacy_popup.click_here1' | translate}} </a>
          <a href="{{bpostBE}}{{'privacy_popup.privacy_link2' | translate}}" target="_blank" class="Text">
            {{'privacy_popup.click_here2' | translate}} <img src="/assets/images/privacyLinkImage.svg"
                 class="right">
          </a></p>
      </div>
      <div></div>
      <div class="row hidden-xs">
        <div class="col-md-6"><button mat-raised-button class="Box_No pull-right"
                  (click)="openSupportModal()">{{'privacy_popup.no_button_text' | translate}}</button></div>
        <div class="col-md-6"><button mat-raised-button class="Box_Yes btn-md"
                  (click)="closeModal(true)">{{'privacy_popup.yes_button_text' | translate}}</button></div>
      </div>
      <div class="visible-xs">
        <div class="row">
          <div class="col-xs-12"><button mat-raised-button class="form-group Box_Yes_xs btn-block"
                    (click)="closeModal(true)">{{'privacy_popup.yes_button_text' | translate}}</button></div>
          <div class="col-xs-12"><button mat-raised-button class="Box_No_xs btn-block"
                    (click)="openSupportModal()">{{'privacy_popup.no_button_text' | translate}}</button></div>
          <!-- </div>
                <div class="row"> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

<div class="privacy-popup-rectange center" *ngIf="supportModal">
  <img src="/assets/images/privacy.svg" class="privacy-popup-image">
  <div class="center">
    <p class="Privacy-terms center">{{'privacy_popup.still_need_support' | translate}}</p>
  </div>
  <div class="center">
    <p class="By-selecting-Yes-I center">{{'privacy_popup.support_text1' | translate}}<span
            class="callCenterNumber">{{'privacy_popup.call_center_number' | translate}}</span>{{'privacy_popup.support_text2' | translate}}
    </p>
  </div>
  <div class="code center"># {{callCenterCode}}</div>
  <div class="close_button_div">
    <button mat-raised-button class="Box_No" (click)="closeModal(false)"><span
            class="close_button">{{'privacy_popup.close_button' | translate}}</span></button>
  </div>
</div>
