import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../core/services/common.service';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ActiveStep } from '../../enums/active-step';
import { PaymentFeedback, PaymentFeedbackModalComponent } from '../payment-feedback-modal/payment-feedback-modal.component';
@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.css']
})
export class PaymentDetailComponent implements OnInit {
  @Input()
  public singleItem: any;
  public hidePaymentOption = false;
  public showInvoiceOption = false;
  public minimizedDetails = false;
  public isChecked = false;
  public displayedColumns = ['Description', 'Amount'];
  public dataSource;
  public isDisabled = true;
  public paymentStatusInconsistent: boolean;
  public paymentText;
  public consentText;
  public consentText2;
  public consentText1;
  public webform;
  public endpoint;
  public loading = false;
  @Input()
  public isActivePaymentDetails;
  public readonly paymentStatusSet = [
    'PAID',
    'REFUSED'
  ];

  constructor(
    public translateService: TranslateService,
    public itemsService: ItemsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private loader: LoaderService,
    private readonly dialog: MatDialog,
    private readonly commonService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    jQuery(() => {
      $('a[href = "bpostPDF"]').on('click', (event) => {
        event.preventDefault();
        let bpostPDF = 'https://www.bpost.be/sites/default/files/terms-conditions/General%20Terms%20and%20Conditions%20governing%20the%20fulfilment%20of%20customs%20formalities%20by%20bpost.pdf';
        if (localStorage.getItem('lang') === 'nl') {
          bpostPDF = 'https://www.bpost.be/sites/default/files/terms-conditions/Algemene%20Voorwaarden%20inzake%20het%20vervullen%20van%20douaneformaliteiten%20door%20bpost.pdf';
        } else if (localStorage.getItem('lang') === 'fr') {
          bpostPDF = 'https://www.bpost.be/sites/default/files/terms-conditions/Conditions%20g%C3%A9n%C3%A9rales%20d%27accomplissement%20des%20formalit%C3%A9s%20douani%C3%A8res%20par%20bpost.pdf';
        }
        $('<a>').prop({
          target: '_blank',
          href: bpostPDF
        })[0].click();
      });
    });
    this.dataSource = this.singleItem.dutyAmountDetails;
    this.dataSource.forEach(element => {
      const key = element.description + '_tooltip';
      this.translateService.get(key).subscribe(res => {
        if (res !== key) {
          element.tooltipText = key;
        }
      });
    });
    // Domain/key/webform_key?itemIdentifier=123459789
    this.endpoint = environment.drupalBaseURL + localStorage.getItem('lang');
    this.webform = this.translateService.instant('payment_detail.webform');
    this.endpoint += `${this.webform}?barcode=${this.singleItem.itemCode}`;
    this.paymentText = this.translateService.instant('payment_detail.actionLinkText.text');
    this.consentText = this.translateService.instant('payment_detail.consent_text0');
    this.consentText2 = this.translateService.instant('payment_detail.consent_text2');
    this.consentText1 = this.translateService.instant('payment_detail.consent_text1');

    if ((this.singleItem.activeStep.name === ActiveStep.CHALLENGED) ||
      (this.singleItem.activeStep.name !== ActiveStep.AWAITING && this.singleItem.customsPaymentStatus === 'PAID')) {
      this.hidePaymentOption = true;
    }
    if (this.singleItem.activeStep.name === ActiveStep.AWAITING && this.paymentStatusSet.indexOf(this.singleItem.customsPaymentStatus) !== -1) {
      this.paymentStatusInconsistent = true;
    }
    if (this.commonService.doesProcessStepsContainsPaymentDone(this.singleItem)) {
      this.showInvoiceOption = true;
    }
    this.minimizedDetails = this.singleItem.minimizedDetails;
  }
  expandIcon() {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.payment-detail-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('cost_overview', tabStatus);
    });
  }
  onChkChange(event) {
    this.isChecked = event.checked;
    if (this.isChecked && !this.paymentStatusInconsistent) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  payOnline() {
    const itemCode = this.singleItem.itemCode;
    const itemCreationDate = this.singleItem.key.created;
    const language = localStorage.getItem('lang');
    const currentUrl = window.location.href;
    this.googleAnalyticsService.pushEventOnButtonClick('pay_customs_online');
    if (!this.isChecked) {
      const paymentFeedbackData: PaymentFeedback = {
        itemCode: '',
        paymentStatus: 'INITIATE',
        statusChanged: false,
        calledStatus: 'INITIATE',
        showSingleButton: true,
        itemCreationDate: ''
      };
      this.openFeedbackDialog(paymentFeedbackData);
    } else {
      this.paymentStatusInconsistent = true;
      this.itemsService.initiatePayment(itemCode, itemCreationDate, language, currentUrl).subscribe(
        data => {
          if (data.paymentUrl) {
            window.location.href = data.paymentUrl;
          } else if (data.paymentStatus) {
            const paymentFeedbackData: PaymentFeedback = {
              itemCode: '',
              paymentStatus: data.paymentStatus,
              statusChanged: data.statusChanged,
              calledStatus: 'INITIATE',
              showSingleButton: true,
              itemCreationDate: ''
            };
            this.openFeedbackDialog(paymentFeedbackData);
          } else if (data.error) {
            const paymentFeedbackData: PaymentFeedback = {
              itemCode: '',
              paymentStatus: 'ERROR',
              statusChanged: false,
              calledStatus: 'INITIATE',
              showSingleButton: false,
              itemCreationDate: ''
            };
            this.openFeedbackDialog(paymentFeedbackData);
          }
        });
    }
  }

  refusePayment() {
    this.paymentStatusInconsistent = true;
    const itemCode = this.singleItem.itemCode;
    const itemCreationDate = this.singleItem.key.created;
    this.googleAnalyticsService.pushEventOnButtonClick('refuse_shipment');
    const paymentFeedbackData: PaymentFeedback = {
      itemCode,
      paymentStatus: 'REFUSE_REQUEST',
      statusChanged: false,
      calledStatus: 'REFUSED',
      showSingleButton: false,
      itemCreationDate
    };
    this.openFeedbackDialog(paymentFeedbackData);
  }

  openFeedbackDialog(paymentFeedbackData) {
    const paymentFeedbackDialog = this.dialog.open(PaymentFeedbackModalComponent, {
      width: '584px',
      data: paymentFeedbackData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
    paymentFeedbackDialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.btnId === 'confirmBtn') {
          this.payOnline();
        } else if (result.reload) {
          location.reload();
        }
      }
    });
  }

  downloadReceipt() {
    this.loading = true;
    this.loader.show();
    this.itemsService.downloadPaymentReceipt(this.singleItem.itemCode, this.singleItem.key.created).subscribe(result => {
      if (result && result.success && result.base64string) {
        const date = moment().format('YYYYMMDD');
        const source = `data:application/pdf;base64,${result.base64string}`;
        const link = document.createElement('a');
        link.href = source;
        link.download = `Receipt_${date}_${this.singleItem.itemCode}`;
        link.click();
        this.loading = false;
        this.loader.hide();
      } else {
        const paymentFeedbackData: PaymentFeedback = {
          itemCode: this.singleItem.itemCode,
          paymentStatus: '',
          statusChanged: false,
          calledStatus: 'GENERATE_RECEIPT',
          showSingleButton: true,
          itemCreationDate: null
        };
        this.openFeedbackDialog(paymentFeedbackData);
      }
    });
  }

}
