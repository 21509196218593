<div class="container address-invalid">
  <button type="button" class="xbutton close" style="color:#ef2637;" aria-hidden="true" (click)="closeModal(false, $event)">x</button>
  <div class="iconclass">
    <i class="icon home" aria-hidden="true"></i>
  </div>
  <div class="row">
    <h2 class="text-center">{{'wrong_address.title' | translate}}</h2>
    <div class="message-info-block">
      <div class="status">
        <span [innerHTML]=description></span>
      </div>
      <div class="info">
        <span [innerHTML]=note></span>
      </div>
    </div>
  </div>
  <div class="custom-submit-button">
    <div class="col-sm-6">
      <button id='closeBtn' class="box-back" (click)="goToBack(false, $event)">{{'wrong_address.back' | translate}}</button>
    </div>
    <div class="col-sm-6">
      <button id='closeBtn' class="box-close" (click)="closeModal(false, $event)">{{'wrong_address.close' | translate}}</button>
    </div>
  </div>
</div>
