import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sustainability-pref',
  templateUrl: './sustainabilitypref.component.html',
  styleUrls: ['./sustainabilitypref.component.css']
})
export class SustainabilityPrefComponent implements OnInit {

  singleItem;
  sustainabilityTexts;
  language;
  desc;

  constructor(
    private readonly traslateService: TranslateService
  ) { }

  ngOnInit() {
    this.language = localStorage.getItem('lang');
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    if (this.singleItem[0].sustainability && this.singleItem[0].sustainability.info.negative) {
      this.sustainabilityTexts = this.singleItem[0].sustainability.info.negative[this.language.toUpperCase()];
      this.desc = this.sustainabilityTexts.desc;
    }
  }
}
