import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../../core/services/address.service';
import { CommonService } from '../../../core/services/common.service';
@Component({
  selector: 'app-address-dialog-modal',
  templateUrl: './address-dialog-modal.component.html',
  styleUrls: ['./address-dialog-modal.component.css']
})
export class AddressDialogModalComponent implements OnInit {

  constructor(
    public readonly dialogRef: MatDialogRef<AddressDialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data,
    public readonly translateService: TranslateService,
    public addressService: AddressService,
    public commonService: CommonService) { }
  public address;
  public updateAddress;
  public popupData;
  public suggestionAddress;
  public dialogTitle;
  public dialogDescription;
  public street: string;
  public number;
  public box;
  public postalcode;
  public city;
  public country;
  public confirm;
  public close;
  public productCategoryName;

  ngOnInit() {
    this.dialogTitle = this.translateService.instant('address_dialog.title');
    this.dialogDescription = this.translateService.instant('address_dialog.description');
    const currentadd = this.data;  // existing address from deliveryaddresss.ts via mat dialog data
    this.productCategoryName = currentadd.productCategoryName;
    this.address = {
      street: currentadd.street,
      number: currentadd.number,
      box: currentadd.box,
      postalcode: currentadd.postalCode,
      city: currentadd.city,
      country: this.commonService.getCountryName(currentadd.country)
    };
    console.log('address got in dialog modal to show', this.address);
  }
  onClickSubmit(data, param, event) { // getting typed address from form
    this.updateAddress = {
      itemIdentifier: this.data.itemIdentifier,     // (this.data.externalId) to update with actual barcode
      name: this.data.name,
      city: this.data.city,
      postalCode: this.data.postalCode,
      number: data.number,
      street: data.street,
      country: this.data.country,
      box: data.box
    };
    console.log('data from html box', this.updateAddress);
    localStorage.setItem('updatedAddress', JSON.stringify(this.updateAddress));
    localStorage.setItem('currentAddress', JSON.stringify(this.address));
    const dataforbackend = {
      itemIdentifier: this.data.itemIdentifier,     // (this.data.externalId) to update with actual barcode
      name: this.updateAddress.name,
      municipality: this.updateAddress.city,
      postalCode: this.updateAddress.postalCode,
      streetNumber: this.updateAddress.number,
      streetName: this.updateAddress.street,
      productCategoryName: this.productCategoryName,
      boxNumber: this.updateAddress.box
    };
    this.addressService.onClickChangeAddress(dataforbackend); // calling address service for submiting address
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }
  closeModal(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }
}
