<!-- Comments heading and sub heading -->
<div class="wrapper center-block">
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title comment-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" href="#comments"
           aria-expanded="false" aria-controls="comments" class="collapsed">
          <i class="more-less glyphicon glyphicon-plus"></i>
          <p class="panel-parent-div">{{'comments.heading' | translate}}</p>
          <p class="panel-sub-div">{{'comments.more_info_text' | translate}}</p>
        </a>
      </h4>
    </div>
    <div id="comments" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <p class="cust_ref" *ngIf="comments">{{comments}}</p>
      </div>
    </div>
  </div>
</div>
