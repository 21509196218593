<div class="address-change">
  <div *ngIf="isSuccess">
    <i class="icon home" aria-hidden="true"></i>
  </div>
  <div *ngIf="!isSuccess">
    <i class="icon failed" aria-hidden="true"></i>
  </div>
  <div class="status">
    <span [innerHTML]=addressStatusText></span>
  </div>
  <div class="info">
    <span>{{addressInfoText}}</span>
  </div>
  <div *ngIf="!this.isRetry && isSuccess" class="custom-submit-button">
    <button id='closeBtn' class="Box_Close" (click)="closeModal(true, $event)">{{addressButtonText}}</button>
  </div>
  <div *ngIf="!this.isRetry && !isSuccess" class="custom-submit-button">
    <button id='closeBtn' class="Box_Close" (click)="closeModal(true, $event)">{{addressButtonText}}</button>
  </div>
  <div *ngIf="this.isRetry && !isSuccess" class="custom-submit-button">
    <div class="col-sm-6">
      <button id='closeBtn' class="Box_Close" (click)="closeModal(false, $event)">{{addressButtonText}}</button>
    </div>
    <div class="col-sm-6">
      <button id='closeBtn' class="Box_Retry" (click)="retry(false, $event)">{{addressButtonTextRetry}}</button>
    </div>
  </div>
</div>
