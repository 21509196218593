import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomHashLocationStrategy } from '../app/custom-hash-location-strategy';
import { BtrFaqNewComponent } from './btr-faq-new/btr-faq-new.component';
import { BtrFaqlistNewComponent } from './btr-faqlist-new/btr-faqlist-new.component';
import { BtrHomeComponent } from './btr-home/btr-home.component';
import { BtrLayoutComponent } from './btr-layout/btr-layout.component';
import { BtrSearchComponent } from './btr-search/btr-search.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '', component: BtrLayoutComponent,
    children: [
      { path: 'home', component: BtrHomeComponent },
      { path: 'search', component: BtrSearchComponent },
      { path: 'faqs', component: BtrFaqlistNewComponent },
      { path: 'faq', component: BtrFaqNewComponent },
    ]
  },
  { path: 'lmg', loadChildren: () => import('./modules/lmg/lmg.module').then(m => m.LmgModule) }, // Lazy loading
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/btr/web/' }, // Keep this for custom hash strategy
    { provide: LocationStrategy, useClass: CustomHashLocationStrategy }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
