import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  pushEventOnBlockClick(tabTitle: string, tabStatus: string) {
    window.dataLayer.push({
      tab_title: tabTitle,
      tab_status: tabStatus,
      event_type: 'tab_click',
      event: 'track'
    });
  }

  pushEventOnButtonClick(buttonTitle: string) {
    window.dataLayer.push({
      cta_title: buttonTitle,
      event_type: 'cta_click',
      event: 'track'
    });
  }

  pushEventOnPaymentSuccessful(orderId: string, dutyAmount: number) {
    window.dataLayer.push({
      event: 'eec.purchase',
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: orderId,
            affiliation: 'track',
            revenue: dutyAmount,
          }
        }
      }
    });
  }

  pushEventOnFormSubmit(barcode: string, postalCode: string) {
    window.dataLayer.push({
      postal_code: postalCode,
      barcode,
      event_type: 'submit',
      event: 'track'
    });
  }

  pushVirtualPageViewEvent(language: string, faqId?: number) {
    const dataObject = {
      content_group: 'track_and_trace',
      language,
      user_status: '',
      user_id: '',
      event: 'virtual_page_view'
    };
    if (faqId) {
      const key = 'faq_topic';
      dataObject[key] = faqId;
    }
    window.dataLayer.push(dataObject);
  }
}
