<div class="custom-address-info row">
  <div class="col-xs-12 col-md-12">
    <div class="row">
      <div class="col-xs-12">
        <p class="deliver-to-pref">{{'sign_for_me.title' | translate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="deliver-to-info">
          <span class="step-strong callCenterNumber">{{ 'sign_for_me.subtitle' | translate}}</span>
          <br /><br />
          <p [innerHTML]=sfmTextTranslationLineOne></p>
          <p [innerHTML]=sfmTextTranslationLineTwo></p>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="row">
        <div class="custom-submit-button">
          <button id="setIndicateDeliveryPref" (click)="goToSignForMe()">{{'sign_for_me.button' | translate}}</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
