import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../../core/services/address.service';
import { AddressInvalidModalComponent } from '../../components/address-invalid-modal/address-invalid-modal.component';

@Component({
  selector: 'app-address-confirm-modal',
  templateUrl: './address-confirm-modal.component.html',
  styleUrls: ['./address-confirm-modal.component.css']
})
export class AddressConfirmModalComponent implements OnInit {

  public address;
  public addressToConfirm;
  constructor(
    public readonly dialogRef: MatDialogRef<AddressConfirmModalComponent>,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public readonly data,
    public readonly translateService: TranslateService,
    public addressService: AddressService) { }
  ngOnInit() {
    this.address = this.data.addressSuggestions[0];
    this.addressToConfirm = `${this.address.streetName}
                             ${this.address.streetNumber},
                              ${this.address.box ? this.address.box + ', ' : ''}
                              ${this.address.postalCode}
                              ${this.address.municipality}`;
  }

  getAddressInvalidDialog() {
    const dialogData = {
      productCategoryName: this.data.productCategoryName,
      itemIdentifier: this.data.itemIdentifier
    };
    this.dialog.open(AddressInvalidModalComponent, {
      width: '584px',
      data: dialogData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
    this.dialogRef.close();
  }

  goToBack(): void {
    const storedAddress = JSON.parse(localStorage.getItem('updatedAddress'));
    const currentAddress = JSON.parse(localStorage.getItem('currentAddress'));
    const params = {
      itemIdentifier: storedAddress.itemIdentifier,
      box: currentAddress.box,
      city: storedAddress.city,
      country: storedAddress.country,
      name: storedAddress.name,
      number: currentAddress.number,
      postalCode: storedAddress.postalCode,
      productCategoryName: this.data.productCategoryName,
      street: currentAddress.street,
    };
    this.addressService.openFeedbackDialogChange(params);
    this.dialogRef.close();
  }

  confirm(): void {
    const existingValues = JSON.parse(localStorage.getItem('updatedAddress'));
    const params = {
      itemIdentifier: existingValues.itemIdentifier,
      name: existingValues.name,
      municipality: this.address.municipality,
      postalCode: this.address.postalCode,
      streetNumber: this.address.streetNumber,
      streetName: this.address.streetName,
      boxNumber: this.address.box,
      productCategoryName: this.data.productCategoryName
    };
    console.log('params', params);
    this.addressService.onClickChangeAddress(params);
    this.dialogRef.close();
  }

  closeModal(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }

}
