import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-for-me',
  templateUrl: './sign-for-me.component.html',
  styleUrls: ['./sign-for-me.component.css']
})
export class SignForMeComponent implements OnInit {

  buttonLink: string;
  sfmTextTranslationLineOne: string;
  sfmTextTranslationLineTwo: string;

  constructor(
    private readonly traslateService: TranslateService
  ) { }

  ngOnInit() {
    this.sfmTextTranslationLineOne = this.traslateService.instant('sign_for_me.text0');
    this.sfmTextTranslationLineTwo = this.traslateService.instant('sign_for_me.text1');
  }

  goToSignForMe() {
    this.buttonLink = this.traslateService.instant('sign_for_me.button_link');
    window.open(this.buttonLink, '_blank');
  }
}
