import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../../core/services/address.service';

@Component({
  selector: 'app-address-invalid-modal',
  templateUrl: './address-invalid-modal.component.html',
  styleUrls: ['./address-invalid-modal.component.css']
})
export class AddressInvalidModalComponent implements OnInit {
  public invalidTitle;
  public description;
  public note;
  public back;
  public close;

  constructor(
    public readonly dialogRef: MatDialogRef<AddressInvalidModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data,
    public readonly translateService: TranslateService,
    public addressService: AddressService,
  ) { }

  ngOnInit() {
    this.note = this.translateService.instant('wrong_address.note');
    this.description = this.translateService.instant('wrong_address.description', { productCategoryName: this.data.productCategoryName });
  }


  closeModal(param, event): void {
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }

  goToBack(param, event): void {
    const storedAddress = JSON.parse(localStorage.getItem('updatedAddress'));
    const currentAddress = JSON.parse(localStorage.getItem('currentAddress'));
    const params = {
      itemIdentifier: storedAddress.itemIdentifier,
      box: currentAddress.box,
      city: storedAddress.city,
      country: storedAddress.country,
      name: storedAddress.name,
      number: currentAddress.number,
      postalCode: storedAddress.postalCode,
      productCategoryName: this.data.productCategoryName,
      street: currentAddress.street,
    };
    this.addressService.openFeedbackDialogChange(params);
    const value = {
      reload: param,
      btnId: event.target.id
    };
    this.dialogRef.close(value);
  }

}
