import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-btr-layout',
  templateUrl: './btr-layout.component.html',
  styleUrls: ['./btr-layout.component.css']
})
export class BtrLayoutComponent implements OnInit {

  public showHeaderFooter = true;

  constructor(private readonly loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.headerFooterVisiblility.subscribe((value) => {
      this.showHeaderFooter = value;
    });
  }

}
