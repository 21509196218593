import { Directive, Input, OnInit } from '@angular/core';
import usb from '../assets/js/usabilla';

@Directive({
  selector: '[appBpUsabilla]'
})
export class BpUsabillaDirective implements OnInit {
  constructor() { }

  ngOnInit() {
    usb.usabilla_load();
  }

}
