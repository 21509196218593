import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../core/services/common.service';
import { TnTModule } from '../../enums/tnt-module';

@Component({
  selector: 'app-language-header',
  templateUrl: './language-header.component.html',
  styleUrls: ['./language-header.component.css']
})
export class LanguageHeaderComponent implements OnInit {

  @Input()
  public module = TnTModule.BTR; // defaults to BTR

  @Input()
  public showAppPromoBanner = true; // defaults to true

  public logoPath: string;

  public deviceInfo = null;
  public open: boolean;
  public toggle: boolean;
  public href: string;
  public lang: string;
  public iPhone;
  public android;
  public appStoreNameGeneric;
  public downloadLink;
  public showLogo: boolean;
  public googlePlayStorePackageCode: string;
  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktopDevice: boolean;
  constructor(
    private readonly cookieService: CookieService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly commonService: CommonService,
    private readonly deviceService: DeviceDetectorService,
    private readonly meta: Meta) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url && e.url.includes('/home')) {
          this.showLogo = true;
        } else { this.showLogo = false; }
      });
  }

  ngOnInit() {
    this.open = false;
    this.toggle = false;
    this.href = '';
    this.showLogo = true;
    this.googlePlayStorePackageCode = 'com.bpost.packami_droid';
    this.isMobile = false;
    this.isTablet = false;
    this.isDesktopDevice = false;
    if (window.location.href.indexOf('lang=') > 0) {
      const splitUrl = window.location.href.split('lang=');
      const params = splitUrl[1].split('&');
      params[0] = params[0].toLowerCase();
      this.lang = this.commonService.setLocalStorage(params[0]);
    } else {
      const prefLanguage = localStorage.getItem('preferedLanKey');
      if (typeof prefLanguage !== 'undefined' && prefLanguage !== null) {
        this.lang = this.commonService.setLocalStorage(prefLanguage);
      } else {
        this.lang = this.commonService.setLocalStorage('fr');
      }
    }
    this.setTitleAndLogo(this.lang);
    if (this.cookieService.get('hideMyBpostPromoBanner')) {
      this.showAppPromoBanner = false; // hide promo banner if it has already been hidden before
    }
    this.osDetectFunction();
  }

  goHome() {
    this.commonService.backToHome(this.module);
  }

  createURLBanner() {
    const intentUrl = window.location.host + window.location.pathname + window.location.hash;
    return `intent://${intentUrl}#Intent;package=${environment.googlePlayStorePackageCode};scheme=https;end`;
  }

  useLanguage(language: string) {
    this.href = this.router.url;
    this.href = decodeURI(this.href);

    const qParams = this.router.parseUrl(this.router.url).queryParams;
    qParams.lang = language;
    const splitUrl = [];
    for (const key in qParams) {
      if (key) {
        splitUrl.push(`${key}=${qParams[key]}`);
      }
    }
    this.location.replaceState(`${this.href.split('?')[0]}?${splitUrl.join('&')}`);
    this.commonService.setLocalStorage(language);

    this.translate.use(language);
    this.lang = language;
    document.documentElement.lang = language;
    const component = this.href.split('?')[0];
    this.router.navigate([component], { queryParams: qParams });
    this.setTitleAndLogo(this.lang);

    const langValue = localStorage.getItem('lang');
    if (typeof langValue !== 'undefined' && langValue !== null) {
      localStorage.setItem('preferedLanKey', langValue);
    }
  } // use language end

  osDetectFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.os === 'iOS' || this.deviceInfo.os === 'Mac') {
      this.iPhone = true;
      // Add apple itunes app id dynamically
      if (this.showAppPromoBanner) {
        this.meta.addTag({ name: 'apple-itunes-app', content: 'app-id=1279683771' });
      }
    } else if (this.deviceInfo.os === 'Android') {
      this.android = true;
    } else {
      this.appStoreNameGeneric = true;
    }
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  openApp() {
    this.downloadLink = this.createURLBanner();
    window.location.href = this.downloadLink;
    this.closeAppPromoBanner();
  }

  closeAppPromoBanner() {
    this.cookieService.set('hideMyBpostPromoBanner', 'true', 365, 'Bpost T&T Website');
    this.showAppPromoBanner = false;
  }

  // Title change based on language
  setTitleAndLogo(language) {
    // title
    if (language === 'en') {
      this.titleService.setTitle('Track your shipment');
    } else if (language === 'fr') {
      this.titleService.setTitle('Suivez votre envoi');
    } else if (language === 'nl') {
      this.titleService.setTitle('Volg je zending');
    } else {
      this.titleService.setTitle('Suivez votre envoi');
    }
    // logo
    if (this.module === TnTModule.LMG) {
      this.logoPath = '/assets/images/lmg/lmg-logo.svg';
    } else {
      this.logoPath = '/assets/images/bpost-logo.png'; // defaults to bpost logo path
    }
  }
}
