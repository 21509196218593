import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../core/services/common.service';
import { TnTModule } from '../../enums/tnt-module';

@Component({
  selector: 'app-search-summary',
  templateUrl: './search-summary.component.html',
  styleUrls: ['./search-summary.component.css']
})
export class SearchSummaryComponent implements OnInit {

  @Input()
  public module = TnTModule.BTR; // defaults to BTR

  @Input()
  public items: any[] = [];

  @Input()
  public nrOfItemsToShow: number; // if not specified, all items are show, otherwise the showMore toggle is shown

  public showMoreToggle = false;

  private itemIdentifiers: string;
  private itemIdentifier: string;
  private postalCode: string;

  constructor(private router: Router, private route: ActivatedRoute, private commonService: CommonService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.itemIdentifiers = queryParams.itemCodes;
      this.itemIdentifier = queryParams.itemCode;
      this.postalCode = queryParams.postalCode;
    });
  }

  filterByItemCode(itemId: number | string): void {
    const filteredItems = this.items.filter((newItem, index) => {
      return String(newItem.key.id) === String(itemId);
    });
    if (filteredItems && filteredItems.length === 1) {
      localStorage.setItem('SelectedItem', JSON.stringify(filteredItems));
      localStorage.setItem('selectedItemCode', filteredItems[0].itemCode);
      const searchRoute = this.module === TnTModule.LMG ? 'lmg/search' : 'search';
      if (this.postalCode) {
        this.router.navigate([searchRoute], {
          queryParams: {
            lang: localStorage.getItem('lang'), itemCode: this.itemIdentifier, itemCodes: this.itemIdentifiers,
            postalCode: this.postalCode, itemId: filteredItems[0].key.id
          }
        });
      } else {
        this.router.navigate([searchRoute], {
          queryParams: {
            lang: localStorage.getItem('lang'), itemCode: this.itemIdentifier, itemCodes: this.itemIdentifiers,
            itemId: filteredItems[0].key.id
          }
        });
      }
    } else {
      this.commonService.backToHome(this.module);
    }
  }
}
