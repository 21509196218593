<div class="wrapper center-block">
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title parcel-images-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" href="#parcelImages"
           aria-expanded="true" aria-controls="parcelImages" [ngClass]="{'collapsed': !isAwaitingStatusActive}">
          <i class="more-less glyphicon" [ngClass]="{'glyphicon-minus': isAwaitingStatusActive, 'glyphicon-plus': !isAwaitingStatusActive}"></i>
          <p class="panel-parent-div">{{'pictures.heading' | translate}}</p>
          <p class="panel-sub-div">{{'pictures.sub-heading' | translate:{productCategory: getProductCategory()} }}</p>
        </a>
      </h4>
    </div>
    <div id="parcelImages" class="panel-collapse collapse" [ngClass]="{'in': isAwaitingStatusActive}" role="tabpanel" aria-labelledby="headingOne">
      <div class="image-panel-body">
        <div id="parcelImageCarousel" data-interval="false" class="carousel" (swipeleft)="onSwipe($event)" (swiperight)="onSwipe($event)">
          <ol class="carousel-indicators">
            <li *ngFor="let refId of parcelImageRefs; index as i" data-target="#parcelImageCarousel" [attr.data-slide-to]="i" (click)="changeSlide(i)"></li>
          </ol>
          <div class="carousel-inner">
            <div *ngFor="let refId of parcelImageRefs; index as x" class="item" style="touch-action: none;
            user-select: none;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        ">
              <img id="sourceImage" class="img-responsive" [ngClass]="{'loading-image': showLoaderImage}" src="{{imageObject['img'+x]}}" alt="Facing issues with image loading please refresh the page.">
              <img class="light-box-icon" src="/assets/images/expand_1.svg" (click)="openLightBox(x)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="imageModal" role="dialog" aria-hidden="true">
  <div class="controls"><span class="image-icon zoom-in" title="Zoom In" (click)="zoomImage('in')"></span><span class="image-icon zoom-out" title="Zoom Out" (click)="zoomImage('out')"></span><span class="image-icon rotate-left" title="Rotate Left" (click)="rotateImage('left')"></span><span class="image-icon rotate-right" title="Rotate Right" (click)="rotateImage('right')"></span></div>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="modalBody">

        <div id="parcelImageCarouselModal" class="carousel" data-interval="false">
          <div class="carousel-inner">
            <div *ngFor="let refId of parcelImageRefs; index as x" class="imageModalBody item rotate0" data-rotation="0">
              <img class="img-responsive" [ngClass]="{'loading-image': showLoaderImage}" src="{{imageObject['img'+x]}}" alt="Facing issues with image loading please refresh the page.">
            </div>
          </div>
          <!-- Left and right controls -->
          <a class="left carousel-control" href="javascript:void(0)" (click)="onCarousel('prev')">
            <span class="glyphicon glyphicon-chevron-left"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="right carousel-control" href="javascript:void(0)" (click)="onCarousel('next')">
            <span class="glyphicon glyphicon-chevron-right"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

      </div>
    </div>
  </div>
</div>
