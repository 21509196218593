<div class="wrapper center-block">
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title tnt-activity-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" data-parent="#accordion"
           href="#collapsetwo" aria-expanded="false" aria-controls="collapse" class="collapsed">
          <i class="more-less glyphicon glyphicon-plus"></i>
          <p class="panel-parent-div" *ngIf="trackingCategory !== 'UNTRACKED'">{{'TNT_activity.TNT_activity_heading' | translate}}</p>
          <p class="panel-parent-div" *ngIf="trackingCategory === 'UNTRACKED'">{{'TNT_activity.TNT_activity_heading_untracked' | translate}}</p>
          <p class="panel-sub-div">{{tntActivityText}}</p>
        </a>
      </h4>
    </div>
    <div id="collapsetwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <div class="wrapper">
          <ul class="StepProgress">
            <li class="StepProgress-item is-done" *ngFor="let event of events">
              <ul class="innner-ul">
                <div *ngIf="getColor(event)">
                  <li class="first-li" id="text-color">{{getEventDescription(event)}}</li>
                  <li class="second-li items-faqs-font" id="text-color"><span>{{getEventDetails(event)}}</span></li>
                </div>
                <div *ngIf="!getColor(event)">
                  <li class="first-li">{{getEventDescription(event)}}</li>
                  <li class="second-li items-faqs-font"><span>{{getEventDetails(event)}}</span></li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
