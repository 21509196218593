<div *ngIf="items.length>0 && items[0].searchOnCustRef" class="row">
  <div class="col-xs-12 ref-info">
    <div class="glyphicon glyphicon-info-sign"></div>
    <p class="ref-info-text">
      {{'Items_summary.cust_ref_info' | translate}}
    </p>
  </div>
</div>
<div class="container multiple_barcode">
  <!-- Number of items found -->
  <div class="row count_shipment">
    <div class="col-xs-12">
      <p class="count_shipment_p">{{items.length}}/{{items[0].parcelsCount}} {{'Items_summary.shipments_found' | translate}}</p>
    </div>
  </div>

  <div *ngFor="let eachItem of items; index as i" [ngClass]="{'show_desktop_barcode' : nrOfItemsToShow && i > nrOfItemsToShow-1 && i < items.length, 'active' : showMoreToggle==true && i > nrOfItemsToShow-1 && i < items.length}">
    <!-- Key info zone -->
    <div (click)="filterByItemCode(eachItem.key.id)">
      <app-key-info-zone [module]="module" [eachItem]="eachItem">
        <!-- Internal templates to add additional content -->
        <ng-template #additionalDesktopContent>
          <!-- Link to details in desktop view -->
          <div class="row hidden-xs custom-multiple-parcel prevent-click">
            <div class="col-xs-8">
              <p class="parceln">{{'Items_summary.shipment_number' | translate}} {{i+1}}</p>
            </div>
            <div class="col-xs-4 pull-right custom-view-detail-a-parent">
              <a (click)="filterByItemCode(eachItem.key.id)" class="custom_hand_pointer custom-view-detail-a">{{'Items_summary.view_in_detail' | translate}}</a>
            </div>
          </div>
        </ng-template>
        <ng-template #additionalMobileContent>
          <!-- Link to details in mobile view-->
          <div class="container custom_status_parent_mobile visible-xs prevent-click">
            <div class="row visible-xs custom-multiple-parcel">
              <div class="col-xs-8">
                <p class="parceln">{{'Items_summary.shipment_number' | translate}} {{i+1}}</p>
              </div>
              <div class="col-xs-4 pull-right custom-view-detail-a-parent">
                <a (click)="filterByItemCode(eachItem.key.id)" class="custom_hand_pointer custom-view-detail-a">{{'Items_summary.view_in_detail' | translate}}</a>
              </div>
            </div>
          </div>
        </ng-template>
      </app-key-info-zone>
    </div>
  </div>

  <!-- Show more items toggle -->
  <div class="expand hidden-xs">
    <div class="row" *ngIf="nrOfItemsToShow && items.length > nrOfItemsToShow">
      <div class="custom-submit-button">
        <button class="show_more_desktop" *ngIf="!showMoreToggle" (click)="showMoreToggle=!showMoreToggle">{{'Items_summary.show_more_results' | translate}}</button>
        <button class="show_more_desktop" *ngIf="showMoreToggle" (click)="showMoreToggle=!showMoreToggle">{{'Items_summary.show_less_results' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="expand visible-xs">
    <div class="row" *ngIf="nrOfItemsToShow && items.length > nrOfItemsToShow">
      <div class="custom-submit-button">
        <button class="show_more_mobile" *ngIf="!showMoreToggle" (click)="showMoreToggle=!showMoreToggle">{{'Items_summary.show_more_results' | translate}}</button>
        <button class="show_more_mobile" *ngIf="showMoreToggle" (click)="showMoreToggle=!showMoreToggle">{{'Items_summary.show_less_results' | translate}}</button>
      </div>
    </div>
  </div>
</div>
