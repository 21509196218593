import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {

  singleItem;
  item;
  img;
  recaptchaStatus = true;
  imageStatus = false;
  imageToShow: any;
  recaptchaSiteKey;

  constructor(
    private itemService: ItemsService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.recaptchaSiteKey = environment.captchaSiteKey;
    this.singleItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.item = this.singleItem[0];
  }

  expandIcon() {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.signature-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('signature', tabStatus);
    });
  }

  resolved(captchaResponse: string) {
    if (captchaResponse !== null) {
      this.imageStatus = true;
      this.recaptchaStatus = false;
      const refID = this.item.actualDeliveryInformation.signature.refId;
      this.itemService.getImage(refID).subscribe(image => {
        this.imageToShow = 'data:image/jpeg;base64,' + image;
      });
    } else {
      this.imageStatus = false;
      this.recaptchaStatus = true;
    }
  }

}
