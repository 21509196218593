import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private previousURL = new BehaviorSubject('');
  constructor() { }

  getPreviousURL() {
    return this.previousURL.asObservable();
  }
  setPreviousURL(newURL: string) {
    this.previousURL.next(newURL);
  }

  clearPreviousURL() {
    this.previousURL.next('');
  }
}
