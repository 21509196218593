import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, timer } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../core/services/common.service';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ItemsService } from '../../../core/services/items.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ActiveStep } from '../../enums/active-step';
import { TnTModule } from '../../enums/tnt-module';
import { TrackingCategory } from '../../enums/tracking-category';
import { ViewMode } from '../../enums/view-mode';
import { PaymentFeedback, PaymentFeedbackModalComponent } from '../payment-feedback-modal/payment-feedback-modal.component';
import { PrivacyModalComponent } from '../privacy-modal/privacy-modal.component';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.css'],
})
export class ItemDetailComponent implements OnInit, OnDestroy {

  @Input()
  public module = TnTModule.BTR; // defaults to BTR

  @Input()
  public viewMode: ViewMode;

  @Input()
  public singleItem: any;

  public logoPath: string;
  public loading: boolean;
  isForceExecution = false;
  isVisHidHours = false;
  isVisibleDeliveryAddress = true;
  isPickupPointInfoStatus = true;
  isCssDownArrow = true;

  isVisibleCustomerNote = false;
  isVisibleDeliveryTo = false;
  isVisibleSignature = false;
  isVisibleDeliveryPref = false;
  isVisibleComments = false;
  isVisibleFAQ = false;
  isVisibleTnTActivity = false;
  isVisiblePaymentDetails = false;
  isVisibleParcelImages = false;
  isVisibleUntrackedInfo = false;
  isAwaitingStatusActive = false;
  isActivePaymentDetails = true;
  isVisibleMinimizedViewWarning = false;
  isVisibleMapAndStops = false;
  isVisibleSFMBlock = false;
  isVisibleSustainableBlock = false;
  isVisibleSustainablePrefBlock = false;
  showButton = true;
  lang = '';
  init = 0;

  itemIdentifiers = '';
  itemIdentifier = '';
  postalCode = '';
  itemId = '';
  paymentStatus = '';
  deeplink = true;
  orderId = '';
  count = 0;
  dataSubscription: Subscription;
  timerSubscription: Subscription;
  itemonroundstatus = false;
  public mapData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private itemService: ItemsService,
    private loader: LoaderService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.itemIdentifiers = queryParams.itemCodes;
      this.itemIdentifier = queryParams.itemCode;
      this.postalCode = queryParams.postalCode;
      this.itemId = queryParams.itemId;
      this.paymentStatus = queryParams.paymentStatus;
      this.orderId = queryParams.transactionid;
    });
    this.initProcess();
    if (this.module === TnTModule.LMG) {
      this.logoPath = '/assets/images/lmg/lmg-logo.svg';
    } else {
      this.logoPath = '/assets/images/bpost-logo.png'; // defaults to bpost logo path
    }
    this.googleAnalyticsService.pushVirtualPageViewEvent(localStorage.getItem('lang'));
  }
  ngOnDestroy() {
    if (this.itemonroundstatus) {
      this.timerSubscription.unsubscribe();
      this.dataSubscription.unsubscribe();
    }
  }

  private initProcess(): void {
    this.isVisibleCustomerNote = false;
    this.isVisibleDeliveryTo = false;
    this.isVisibleSignature = false;
    this.isVisibleDeliveryPref = false;
    this.isVisibleComments = false;
    this.isVisibleFAQ = false;
    this.isVisibleTnTActivity = false;
    this.isVisiblePaymentDetails = false;
    this.isVisibleParcelImages = false;
    this.isVisibleUntrackedInfo = false;
    this.isAwaitingStatusActive = false;
    this.isActivePaymentDetails = true;
    this.isVisibleMinimizedViewWarning = false;
    this.isVisibleMapAndStops = false;
    if (this.paymentStatus) {
      this.loading = true;
      this.loader.show();
    }
    if (this.singleItem[0].actualDeliveryInformation) {
      this.isForceExecution = this.singleItem[0].actualDeliveryInformation.isForcedExecution;
    } else {
      this.isForceExecution = false;
    }
    this.displayDeliveryAddress();
    this.checkPickupPointStatus(); // checking for 'pickup point' should be there 'delivery point info'
    this.displayDetails();
    this.displayPaymentFeedback();
    this.displayPaymentDetails();
    this.displayParcelImages();
    this.displayUntrackedInfo();
    this.displayMinimizedViewWarning();
    this.displayMapAndStops();
    if (this.isVisibleMapAndStops) {
      this.timerSubscription = timer(0, environment.itemonroundstatus_time).subscribe(() => {
        this.getItemOnRoundData();
      });
      this.itemonroundstatus = true;
    }
  }

  private displayDeliveryAddress(): void {
    if (((this.singleItem[0].requestedDeliveryMethod === 'POSTAL_POINT' ||
      this.singleItem[0].requestedDeliveryMethod === 'KARIBOO_POINT' ||
      this.singleItem[0].requestedDeliveryMethod === 'POSTAL_OFFICE' ||
      this.singleItem[0].requestedDeliveryMethod === 'PARCEL_LOCKER' ||
      this.singleItem[0].requestedDeliveryMethod === 'HOME') && this.singleItem[0].deliveryPoint) || (this.singleItem[0].requestedDeliveryMethod === 'HOME' && !this.singleItem[0].receiver)) {
      this.isVisibleDeliveryAddress = false;
    }
  }

  /*
    this is just safe check, general if delivery type is 'pickup point'
    then we should receive the delivery point info, so we are checking that.
  */
  private checkPickupPointStatus(): void {
    if ((this.singleItem[0].requestedDeliveryMethod === 'POSTAL_POINT' ||
      this.singleItem[0].requestedDeliveryMethod === 'KARIBOO_POINT' ||
      this.singleItem[0].requestedDeliveryMethod === 'POSTAL_OFFICE' ||
      this.singleItem[0].requestedDeliveryMethod === 'PARCEL_LOCKER') && !this.singleItem[0].deliveryPoint) {
      this.isPickupPointInfoStatus = false;
    }
  }

  private displayDetails(): void {
    if (this.module === TnTModule.BTR && this.singleItem[0].helpTextKey !== 'HIDE') {
      this.isVisibleCustomerNote = true;
    } else {
      this.isVisibleCustomerNote = false;
    }

    if ((Object.keys((this.singleItem[0].processSteps)).length !== 0) && !this.singleItem[0].isDeliveredStatusActive && ((this.module === TnTModule.BTR && (((this.singleItem[0].processSteps[0].name !== 'return_to_sender' || !this.singleItem[0].backToSenderReasonCode)
      && this.isVisibleDeliveryAddress && this.isPickupPointInfoStatus && this.singleItem[0].receiver && Object.keys(this.singleItem[0].receiver).length > 0) ||
      ((this.singleItem[0].processSteps[0].name === 'return_to_sender' || !this.singleItem[0].backToSenderReasonCode)
        && this.isVisibleDeliveryAddress && this.isPickupPointInfoStatus && this.singleItem[0].receiver && Object.keys(this.singleItem[0].receiver).length > 0))) ||
      this.singleItem[0].pickupPointName)) {
      this.isVisibleDeliveryTo = true;
    } else {
      this.isVisibleDeliveryTo = false;
    }

    if (this.module === TnTModule.BTR && !this.singleItem[0].minimizedDetails && this.singleItem[0].signatureViewType === 'CAPTCHA') {
      this.isVisibleSignature = true;
    } else {
      this.isVisibleSignature = false;
    }

    // condition modified on 20-FEB-2020 due to DCS-11593. changed by sateesh & narasimha
    if (this.module === TnTModule.BTR && (this.singleItem[0].showDeliveryPreferences || (this.singleItem[0].preferenceText && this.singleItem[0].preferenceText.txt))) {
      this.isVisibleDeliveryPref = true;
    } else {
      this.isVisibleDeliveryPref = false;
    }

    // Only show in BTR module and when faqs are available
    if (this.module === TnTModule.BTR && this.singleItem[0].faqIds && Object.keys(this.singleItem[0].faqIds).length > 0) {
      this.isVisibleFAQ = true;
    }

    // Only show when events are available
    if (this.singleItem[0].events && Object.keys(this.singleItem[0].events).length > 0) {
      this.isVisibleTnTActivity = true;
    }

    if (this.module === TnTModule.LMG && this.singleItem[0].comments && Object.prototype.toString.call(this.singleItem[0].comments) !== '[object Object]') {
      this.isVisibleComments = true;
    }

    // only shows when sfm is eligible
    if (this.module === TnTModule.BTR && this.singleItem[0].isEligibleForSFM) {
      this.isVisibleSFMBlock = true;
    }

    if (this.module === TnTModule.BTR && this.singleItem[0].sustainability &&
      this.singleItem[0].sustainability.info &&
      this.singleItem[0].sustainability.info.positive) {
      this.isVisibleSustainableBlock = true;
    }

    if (this.module === TnTModule.BTR && this.singleItem[0].sustainability &&
      this.singleItem[0].sustainability.info &&
      this.singleItem[0].sustainability.info.negative) {
      this.isVisibleSustainablePrefBlock = true;
    }

  }

  private displayPaymentDetails() {
    this.isVisiblePaymentDetails = this.singleItem[0].showPaymentRequired;
    if (this.commonService.doesProcessStepsContainsPaymentDone(this.singleItem[0])) {
      this.isActivePaymentDetails = false;
    }
  }
  private displayParcelImages() {
    if (this.singleItem[0].parcelImageReferences && Object.keys(this.singleItem[0].parcelImageReferences).length > 0 &&
      (this.singleItem[0].customsPaymentStatus !== 'REFUSED' && this.singleItem[0].customsPaymentStatus !== 'EXPIRED')) {
      this.isVisibleParcelImages = true;
    }
    if (this.singleItem[0].activeStep && this.singleItem[0].activeStep.name === ActiveStep.AWAITING) {
      this.isAwaitingStatusActive = true;
    }
  }

  private displayUntrackedInfo(): void {
    if (this.singleItem[0].trackingCategory === TrackingCategory.UNTRACKED) {
      this.isVisibleUntrackedInfo = true;
    }
  }

  private displayMinimizedViewWarning(): void {
    if (this.module === TnTModule.BTR && this.singleItem[0].minimizedDetails && (this.singleItem[0].dutyAmount || (this.singleItem[0].customs && this.singleItem[0].customs.Amount === 0))) {
      this.isVisibleMinimizedViewWarning = true;
    }
  }

  private displayPaymentFeedback(): void {
    // Only display when payment feedback parameters are available (fetched from query params)
    if ((this.itemIdentifier || this.itemIdentifiers) && (this.paymentStatus === 'PAID' || this.paymentStatus === 'FAILED' || this.paymentStatus === 'REFUSED' || this.paymentStatus === 'INPROCESS')) {
      // let paymentFeedbackDialog;
      // const searchRoute = this.module === TnTModule.LMG ? 'lmg/search' : 'search';
      this.itemService.finalizePayment(this.singleItem[0].itemCode, this.singleItem[0].key.created, this.paymentStatus, this.orderId).subscribe(data => {
        this.loading = false;
        this.loader.hide();
        const searchRoute = this.module === TnTModule.LMG ? 'lmg/search' : 'search';
        if (data.error || data.paymentStatus === null) {
          const paymentFeedbackData: PaymentFeedback = {
            itemCode: '',
            paymentStatus: 'ERROR',
            statusChanged: false,
            calledStatus: 'FINALIZE',
            showSingleButton: true,
            itemCreationDate: ''
          };
          this.openFeedbackModal(paymentFeedbackData);
        } else {
          if (data.paymentStatus === 'CANCELLED' || data.paymentStatus === 'FAILED') {
            this.router.navigate([searchRoute], {
              queryParams: { lang: localStorage.getItem('lang'), itemCodes: this.itemIdentifiers, itemCode: this.itemIdentifier, postalCode: this.postalCode }
            });
          } else {
            if (data.paymentStatus === 'PAID' && data.statusChanged) {
              this.googleAnalyticsService.pushEventOnPaymentSuccessful(this.orderId, this.singleItem[0].dutyAmount);
            }
            const paymentFeedback: PaymentFeedback = {
              itemCode: this.itemIdentifier,
              paymentStatus: data.paymentStatus,
              statusChanged: data.statusChanged,
              calledStatus: 'FINALIZE',
              showSingleButton: true,
              itemCreationDate: ''
            };
            this.openFeedbackModal(paymentFeedback);
          }
        }
      });
    }
  }

  private displayMapAndStops() {
    if (this.module === TnTModule.BTR && !this.singleItem[0].minimizedDetails && this.singleItem[0].activeStep &&
      (this.singleItem[0].activeStep.knownProcessStep === ActiveStep.ON_THE_WAY_TO_YOU ||
        this.singleItem[0].activeStep.knownProcessStep === ActiveStep.ON_THE_WAY_TO_SENDER)) {
      this.isVisibleMapAndStops = true;
    }
  }

  private getItemOnRoundData() {
    this.itemService.getItemOnOnRoundStatusByBarcodeData(this.singleItem[0].itemCode, this.singleItem[0].receiver.postcode);
    this.dataSubscription = this.itemService.apiData().subscribe(data => {
      if (data && data.itemOnRoundStatus && data.itemOnRoundStatus.lastKnownLocation && data.itemOnRoundStatus.targetLocation) {
        this.isVisibleMapAndStops = true;
        this.mapData = data;
        if (!data.itemOnRoundStatus.lastKnownLocation[0].long[0] || !data.itemOnRoundStatus.lastKnownLocation[0].lat[0] || !data.itemOnRoundStatus.targetLocation[0].long[0] || !data.itemOnRoundStatus.targetLocation[0].lat[0]) {
          this.isVisibleMapAndStops = false;
        }
      } else {
        this.isVisibleMapAndStops = false;
      }
    });
  }


  public openFeedbackModal(paymentFeedbackData) {
    const searchRoute = this.module === TnTModule.LMG ? 'lmg/search' : 'search';
    const paymentFeedbackDialog = this.dialog.open(PaymentFeedbackModalComponent, {
      width: '584px',
      data: paymentFeedbackData,
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });
    paymentFeedbackDialog.afterClosed().subscribe(() => {
      this.router.navigate([searchRoute], {
        queryParams: { lang: localStorage.getItem('lang'), itemCodes: this.itemIdentifiers, itemCode: this.itemIdentifier, postalCode: this.postalCode }
      }).then(() => {
        window.location.reload();
      });
    });
  }

  public isVisibleHideHours(): void {
    this.isVisHidHours = !this.isVisHidHours;
    this.isCssDownArrow = !this.isCssDownArrow;
  }

  public openDirection(lat, long): void {
    const url = environment.googleMapsLink + lat + ',' + long;
    window.open(url, '_blank');
  }

  public backToSummary(): void {
    const searchRoute = this.module === TnTModule.LMG ? 'lmg/search' : 'search';
    if (this.singleItem[0].operationalMulticolliId) {
      this.router.navigate([searchRoute], {
        queryParams: { lang: localStorage.getItem('lang'), itemCodes: this.itemIdentifiers, itemCode: this.singleItem[0].operationalMulticolliId, postalCode: this.postalCode }
      });
    } else {
      this.router.navigate([searchRoute], {
        queryParams: { lang: localStorage.getItem('lang'), itemCodes: this.itemIdentifiers, itemCode: this.itemIdentifier, postalCode: this.postalCode }
      });
    }
  }

  public goHome(): void {
    this.commonService.backToHome(this.module);
  }

  public openForm() {
    this.showButton = false;
    if (this.init === 0 || this.lang !== localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang');
      const item = JSON.parse(localStorage.getItem('SelectedItem'))[0];
      if (window.history.length > 2) {
        this.deeplink = false;
      }
      const postalCode = this.postalCode;
      const itemCode = this.itemIdentifier;
      const src = environment.chatBotURL + '/index.html?lang=' + this.lang + '&itemCode=' + itemCode + '&postalCode=' + postalCode + '&deeplink=' + this.deeplink;
      // keep this block to avoid glitch issue
      const iframe = $('<iframe id="ifrm" " src="' + src + '"  width="300" height="480" scrolling="no" frameborder="0"><\/iframe>').css('visibility', 'hidden');
      iframe.appendTo('#chatbot2');
      setTimeout(() => {
        $('#ifrm').css('visibility', 'visible');
      }, 800);
      // keep this block to avoid glitch issue
      this.init = this.init + 1;
    } else {
      document.getElementById('ifrm').style.height = '480px';
    }
    //
    window.addEventListener('message', this.closeChatbot.bind(this));
  }

  public closeChatbot(e): void {
    this.showButton = true;
    // console.log(this);
    // console.log(this.showButton);
    // if (e.origin === environment.chatBotURL && e.data === '0') {
    if (e.origin === location.origin && e.data === '0') {
      const el2 = document.getElementById('ifrm');
      el2.style.height = '0';
    }
  }

  public openDialog(primaryKey): void {
    const dialogRef = this.dialog.open(PrivacyModalComponent, {
      width: '584px',
      data: { privacyModal: true, supportModal: false, itemPrimaryKey: primaryKey },
      disableClose: true,
      maxWidth: '98vw',
      maxHeight: '98vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cookieService.set('tntchatbot_gdpr_user_consent_bpost', 'true', 365);
        this.openForm();
      }
    });
  }

  public openChatbot(primaryKey): void {
    const isConsented = this.cookieService.check('tntchatbot_gdpr_user_consent_bpost');
    if (isConsented) {
      this.openForm();
    } else {
      this.openDialog(primaryKey);
    }
  }

  public getProductCategory(): string {
    return this.commonService.getProductCategoryName(this.singleItem[0]);
  }

}
