<div class="container">
  <button type="button" class="xbutton close" style="color:#ef2637;" (click)="closeModal(false, $event)" aria-hidden="true">x</button>
  <div class="iconclass">
    <i class="icon home" aria-hidden="true"></i>
  </div>
  <div class="row">
    <form class="register-form" #filladdress="ngForm" (ngSubmit)="filladdress.form.valid && onClickSubmit(filladdress.value,false, $event)">
      <h2 class="text-center" [innerHTML]=dialogTitle></h2>
      <h5 class="text-center" [innerHTML]=dialogDescription></h5>
      <div class="form-group">
        <div class="col-12 col-sm-6">
          <label name="streetName" class="control-label">{{'address_dialog.street' | translate}}</label>
          <input type="text" name="street" class="form-control" [(ngModel)]="address.street" #street="ngModel" required maxlength="250" [ngClass]="{'is-invalid': street.errors?.required}">
        </div>
        <div class="myClass col-sm-3">
          <label class="control-label">{{'address_dialog.number' | translate}}</label>
          <input type="text" name="number" class="form-control" [(ngModel)]="address.number" #number="ngModel" required maxlength="30" [ngClass]="{'is-invalid': number.errors?.required}">
        </div>
        <div class="myClass col-sm-3">
          <label class="control-label">{{'address_dialog.box' | translate}}</label>
          <input type="text" name="box" class="form-control" maxlength="30" [(ngModel)]="address.box" ngModel>
        </div>
      </div>

      <div class="form-group">
        <div class="myClass col-sm-2">
          <label class="control-label">{{'address_dialog.postalcode' | translate}}</label>
          <input type="text" name="postalcode" class="form-control" [(ngModel)]="address.postalcode" disabled ngModel>
        </div>
        <div class="myClass col-sm-4">
          <label class="control-label">{{'address_dialog.city' | translate}}</label>
          <input type="text" name="city" class="form-control" [(ngModel)]="address.city" disabled ngModel>
        </div>
        <div class="col-sm-6">
          <label class="control-label">{{'address_dialog.country' | translate}}</label>
          <input type="text" name="country" class="form-control" [(ngModel)]="address.country" disabled ngModel>
        </div>
      </div>

      <div class="form-group submit-button">
        <div class="col-sm-6">
          <button id='closeBtn' type="button" class="Box_Close" (click)="closeModal(false, $event)">{{'address_dialog.close' | translate}}</button>
        </div>
        <div class="col-sm-6">
          <button id='closeBtn' class="Box_Confirm" type="submit" value="submit" [disabled]="filladdress.invalid" [ngClass]="{'disable-btn' :  filladdress.invalid}">{{'address_dialog.confirm' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
