<div *ngIf="isNewSearchBar; then newTemplate else oldTemplate"></div>

<ng-template #newTemplate>
  <!-- new template - desktop view -->
  <div class="hidden-xs">
    <div class="form-inline search-input-fields">
      <div class="form-group">
        <label for="itemCode" class="label-styling">{{'search.barcode' | translate }}
        </label>
        <br>
        <input type="text" [(ngModel)]="itemIdentifier" (keyup.enter)="getItems(itemIdentifier, postalCode)" class="barcode-field Text form-control" id="search-input0" autocomplete="on" spellcheck="" tabindex="1" placeholder="{{ 'search.barcode_placeholder' | translate }}">
      </div>
      <div class="form-group">
        <label for="postalCode" class="label-styling">{{'search.Postcode' | translate }}
          <a class="tooltip-parent"><img class="hand-pointer know-postal-code" src="/assets/images/tooltip.png"><span class="tooltiptext">{{ 'search.tooltip_postalcode' | translate }}</span></a>
        </label><br>
        <input type="text" [(ngModel)]="postalCode" (keyup.enter)="getItems(itemIdentifier, postalCode)" class="postcode-field form-control" tabindex="2" id="postal-input0" placeholder="{{ 'search.potalcode_placeholder' | translate }}">
      </div>
      <div class="form-group search-button-parent">
        <button class="search-button" id="search-submit" tabindex="3" (click)="getItems(itemIdentifier, postalCode)">
          <img src="/assets/images/right.svg">
        </button>
      </div>
    </div>
    <div class="row">
      <div>
        <div class="col-xs-12 validation-banner" *ngIf="itemCodeRequired">
          <b> <img style="padding-right:4px" src="/assets/images/error.png"> {{ 'homepage.OopsTitle' | translate }}</b>
          <p>{{ 'homepage.ItemCodeRequired1' | translate }}</p>
          <p>{{ 'homepage.ItemCodeRequired2' | translate }}</p>
        </div>
        <div class="col-xs-12 validation-banner" *ngIf="postalCodeNotValid">
          <b><img style="padding-right:4px" src="/assets/images/error.png"> {{ 'homepage.POSTAL_CODE_INVALID_TITLE' | translate }}</b>
          <p [innerHTML]=" 'homepage.RECEIVER_POSTAL_CODE_NOT_VALID' | translate"></p>
        </div>
        <div class="col-xs-12 validation-banner" *ngIf="noResultsFound">
          <b><img style="padding-right:4px" src="/assets/images/error.png"> {{ 'homepage.NoResultFoundTitle' | translate }}</b>
          <p [innerHtml]=" 'homepage.NoItemsFoundText1' | translate"></p>
          <p [innerHtml]=" 'homepage.NoItemsFoundText2SearchFromPage' | translate"></p>
        </div>
        <div class="col-xs-12 validation-banner" *ngIf="generalizedError">
          <b><img style="padding-right:4px" src="/assets/images/error.png"> {{ 'homepage.TechnicalErrorTitle' | translate }}</b>
          <p>{{ 'homepage.TechnicalError1' | translate }}</p>
        </div>
        <div class="col-xs-12 validation-banner" *ngIf="untracked">
          <b><img style="padding-right:4px" src="/assets/images/error.png"> {{ 'homepage.UntrackedHTitle' | translate }}</b>
          <p>{{ 'homepage.UntrackedHText' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- new template - mobile view -->
  <div class="visible-xs" style="height: 100%;">
    <p class="mobile-search-widget-title">{{'homepage.TrackyourshipmentText' | translate }}</p>
    <div class="form-inline">
      <div class="form-group">
        <label for="itemCode" class="label-styling-mobile">{{'search.barcode' | translate }}</label>
        <br>
        <input type="text" [(ngModel)]="itemIdentifier" (keyup.enter)="getItems(itemIdentifier, postalCode)" class="barcode-field-mobile" id="search-input1" autocomplete="on" spellcheck="" placeholder="{{ 'search.barcode_placeholder' | translate }}">
      </div>
      <div class="form-group">
        <label for="postalCode" class="label-styling-mobile">{{'search.Postcode' | translate }}</label>
        <img class="know-postal-code" (click)="openModel=true" src="/assets/images/tooltip.png">
        <br>
        <div *ngIf="openModel" class="modal">
          <div class="modal-content">
            <h4 class="heading_postcode">{{'search.Postcode' | translate }}<span><img src="/assets/images/close.svg" class="close-popup" (click)="openModel=false"></span></h4>
            <p class="content_postcode">{{ 'search.tooltip_postalcode' | translate }}</p>
          </div>
        </div>
        <input type="text" [(ngModel)]="postalCode" (keyup.enter)="getItems(itemIdentifier, postalCode)" class="postcode-field-mobile" id="postal-input1" placeholder="{{ 'search.potalcode_placeholder' | translate }}">
        <span class="form-group search-button-parent">
          <button class="search-button-mobile" id="search-submit" (click)="getItems(itemIdentifier, postalCode)">
            <img src="/assets/images/right.svg">
          </button>
        </span>
      </div>
    </div>
    <div class="row">
      <div>
        <div class="col-xs-12 validation-banner-mobile" *ngIf="itemCodeRequired">
          <b>{{ 'homepage.OopsTitle' | translate }}</b>
          <p>{{ 'homepage.ItemCodeRequired1' | translate }}</p>
          <p>{{ 'homepage.ItemCodeRequired2' | translate }}</p>
        </div>
        <div class="col-xs-12 validation-banner-mobile" *ngIf="postalCodeNotValid">
          <b> <img style="padding-right:4px" src="/assets/images/error.png"> {{ 'homepage.POSTAL_CODE_INVALID_TITLE' | translate }}</b>
          <p [innerHTML]=" 'homepage.RECEIVER_POSTAL_CODE_NOT_VALID' | translate"></p>
        </div>
        <div class="col-xs-12 validation-banner-mobile" *ngIf="noResultsFound">
          <b><img style="padding-right:4px" src="/assets/images/error.png">{{ 'homepage.NoResultFoundTitle' | translate }}</b>
          <p>{{ 'homepage.NoItemsFoundText1' | translate }}</p>
          <p>{{ 'homepage.NoItemsFoundText2SearchFromPage' | translate }}</p>
          <p [innerHTML]="'homepage.NoItemsFoundTextDIV' | translate"></p>
        </div>
        <div class="col-xs-12 validation-banner-mobile" *ngIf="generalizedError">
          <b><img style="padding-right:4px" src="/assets/images/error.png">{{ 'homepage.TechnicalErrorTitle' | translate }}</b>
          <p>{{ 'homepage.TechnicalError1' | translate }}</p>
        </div>
        <div class="col-xs-12 validation-banner-mobile" *ngIf="untracked">
          <b><img style="padding-right:4px" src="/assets/images/error.png">{{ 'homepage.UntrackedHTitle' | translate }}</b>
          <p>{{ 'homepage.UntrackedHText' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #oldTemplate>
  <!-- old template - desktop view -->
  <div class="row hidden-xs">
    <div class="col-xs-12 col-sm-offset-3 col-sm-9 searchbox_float">
      <div class="input-group search-box-container">
        <input type="text" [(ngModel)]="itemIdentifier" (keyup.enter)="getItems(itemIdentifier, postalCode)" style="width:49% !important;" id="search-input2" autocomplete="on" spellcheck="" class="barcode_code search-input form-control" placeholder="{{ 'search.barcode_placeholder' | translate }}">
        <input type="text" [(ngModel)]="postalCode" (keyup.enter)="getItems(itemIdentifier, postalCode)" style="width:49% !important;" id="postal-input2" autocomplete="on" spellcheck="" class="postal_code search-input form-control" placeholder="{{ 'search.potalcode_placeholder' | translate }}">
        <span class="input-group-addon">
          <button class="btn" (click)="getItems(itemIdentifier, postalCode)">
            <span class="hidden-xs">{{ 'search.btntxt' | translate }}</span>
            <span class="glyphicon glyphicon-search hidden-sm hidden-md hidden-lg"></span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <!-- old template - mobile view -->
  <div class="row hidden-sm hidden-md hidden-lg">
    <div class="col-xs-12 col-sm-offset-3 col-sm-9">
      <div class="input-group search-box-container">
        <input type="text" [(ngModel)]="itemIdentifier" (keyup.enter)="getItems(itemIdentifier, postalCode)" id="search-input3" autocomplete="on" spellcheck="" class="barcode_code search-input mobile form-control" placeholder="{{ 'search.barcode_placeholder' | translate }}">
      </div>
      <div class="input-group search-box-container">
        <input type="text" [(ngModel)]="postalCode" (keyup.enter)="getItems(itemIdentifier, postalCode)" id="postal-input3" autocomplete="on" spellcheck="" class="postal_code search-input mobile form-control" placeholder="{{ 'search.potalcode_placeholder' | translate }}">
        <span class="input-group-addon">
          <button class="btn" id="search-submit" (click)="getItems(itemIdentifier, postalCode)">
            <span class="glyphicon glyphicon-search"></span>
          </button>
        </span>
      </div>
    </div>
  </div>
</ng-template>
