<div class="container">
  <div class="iconclass">
    <i class="icon home" aria-hidden="true"></i>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div *ngIf="showRadioButton" class="form-group">
      <div class="sugg-container">
        <h2 class="text-center">{{'address_suggestion.title' | translate}}</h2>
        <h5 class="text-center">{{'address_suggestion.description' | translate}}</h5>
        <div class="sugg-list">
          <label *ngFor="let add of addressSuggestions">
            <input type="radio" formControlName="suggestion" [value]="add" ngModel (change)="onChangeSelect(1, $event)">
            {{add.streetName}} {{add.streetNumber}}, {{add.box ? add.box + ', ' : ''}}{{add.postalCode}}, {{add.municipality}}
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="!showRadioButton" class="form-group">
      <h2 class="text-center">{{'address_suggestion.title' | translate}}</h2>
      <h5 class="text-center">{{'address_suggestion.description' | translate}}</h5>
      <select formControlName="suggestion" class="form-control" aria-placeholder="Select Address" (change)="onChangeSelect(2, $event.target.value)">
        <option value="" selected disabled>{{'address_suggestion.defaultDropdownText' | translate}}</option>
        <option *ngFor="let add of addressSuggestions" [ngValue]="add">
          {{add.streetName}} {{add.streetNumber}}, {{add.box ? add.box + ', ' : ''}}{{add.postalCode}}, {{add.municipality}}
        </option>
      </select>
    </div>
    <p class="text-center">
      <span>{{'address_suggestion.note.1' | translate}}</span>
      <span (click)='getAddressInvalidDialog()' [innerHTML]=link></span>
      <span>{{'address_suggestion.note.2' | translate}}</span>
    </p>
    <div class="form-group submit-button">
      <div class="myClass col-sm-6">
        <button id='closeBtn' type="button" class="Box_Close"
                (click)="closeModal(false, $event)">{{'address_suggestion.close' | translate}}</button>
      </div>
      <div class="col-sm-6">
        <button id='closeBtn' class="Box_Confirm" type="submit" value="submit" [disabled]="buttonEnable" [ngClass]="{'disable-btn': buttonEnable}">{{'address_suggestion.confirm' | translate}}</button>
      </div>
    </div>
  </form>
</div>
