import { Component, Input, OnInit } from '@angular/core';
import { TnTModule } from '../../enums/tnt-module';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input()
  public loading: boolean;

  @Input()
  public module = TnTModule.BTR; // defaults to BTR

  public logoPath: string;

  constructor() { }

  ngOnInit() {
    if (this.module === TnTModule.LMG) {
      this.logoPath = '/assets/images/lmg/spinner-logo.png';
    } else {
      this.logoPath = '/assets/images/bpost-logo-small.png'; // defaults to btr logo path
    }
  }

}
