import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ItemsService } from '../../../core/services/items.service';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.css']
})
export class PrivacyModalComponent implements OnInit {
  public privacyModal: boolean;
  public supportModal: boolean;
  public itemPrimaryKey;
  public callCenterCode = '';
  bpostBE: any;
  constructor(
    public dialogRef: MatDialogRef<PrivacyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly itemService: ItemsService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.privacyModal = this.data.privacyModal;
    this.itemPrimaryKey = this.data.itemPrimaryKey;
    this.bpostBE = environment.drupalBaseURL;
    this.getCallCenterCode();
  }

  openSupportModal() {
    this.privacyModal = false;
    this.supportModal = true;
  }

  closeModal(consent) {
    this.dialogRef.close(consent);
  }
  getCallCenterCode() {
    this.itemService.getCallCenterCode(this.itemPrimaryKey).subscribe(
      data => {
        this.callCenterCode = data.callCenterCode;
      });
  }
}
