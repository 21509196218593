<!-- Loading spinner -->
<app-spinner [loading]="loading"></app-spinner>

<div id="costoverview" class="wrapper center-block">
  <div class="panel panel-default">
    <div class="panel-heading active" role="tab" id="headingOne">
      <h4 class="panel-title payment-detail-block">
        <a role="button" (click)="expandIcon()" data-toggle="collapse" href="#paymentDetail" aria-expanded="true" aria-controls="paymentDetail" [ngClass]="{'collapsed': !isActivePaymentDetails}">
          <i class="more-less glyphicon" [ngClass]="{'glyphicon-minus': isActivePaymentDetails, 'glyphicon-plus': !isActivePaymentDetails}"></i>
          <p class="panel-parent-div">{{'payment_detail.heading' | translate}}</p>
          <p class="panel-sub-div">{{'payment_detail.sub_heading' | translate}}</p>
        </a>
      </h4>
    </div>
    <div id="paymentDetail" class="panel-collapse collapse in" [ngClass]="{'in': isActivePaymentDetails}" role="tabpanel" aria-labelledby="headingOne">
      <div class="payment-panel-body">
        <p class="payment-detail-text1">{{'payment_detail.fee_explanation_text' | translate}}</p>
      </div>
      <div *ngIf="singleItem.dutyAmountDetails.length > 0" class="payment-panel-body">
        <mat-table [dataSource]="dataSource" class="payment-table">
          <ng-container matColumnDef="Description">
            <mat-header-cell *matHeaderCellDef>{{'payment_detail.table_header_description' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.description | translate}}
              <a class="tooltip-parent">
                <img *ngIf='element.tooltipText' class="tooltip-image" src='/assets/images/info_line.svg'>
                <span class="tooltiptext">{{element.tooltipText | translate}}</span>
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Amount">
            <mat-header-cell *matHeaderCellDef>{{'payment_detail.table_header_amount' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">&euro;{{element.amount}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
      <div *ngIf="!hidePaymentOption && !minimizedDetails">
        <div class="payment-panel-body">
          <span [innerHTML]=consentText></span>
          <mat-checkbox [disableRipple]="true" [disabled]="paymentStatusInconsistent" (change)="onChkChange($event)">
            <span [innerHTML]=consentText1></span>
          </mat-checkbox>
          <p [innerHTML]=consentText2></p>
        </div>
        <div id="actionLinks" class="payment-panel-body payment-detail-text1">
          <b><a href={{endpoint}} target="_blank">{{'payment_detail.actionLinkText.text0' | translate}}</a> {{'payment_detail.actionLinkText.text1' | translate}}</b>
          <span [innerHTML]=paymentText></span>
        </div>
        <div class="payment-panel-body">
          <div class="row" class="payment-button-group">
            <button id="btn-refuse" class="payment-no-button" [ngClass]="{'disable-btn' : paymentStatusInconsistent}" [disabled]="paymentStatusInconsistent" (click)="refusePayment()">{{'payment_detail.no_button' | translate}}</button>
            <button id="btn-yes" class="payment-yes-button" [ngClass]="{'disable-btn' :  paymentStatusInconsistent}" [disabled]=" paymentStatusInconsistent" (click)="payOnline()">{{'payment_detail.yes_button' | translate}}</button>
          </div>
        </div>
      </div>
      <div *ngIf="hidePaymentOption && showInvoiceOption && !minimizedDetails && !loading" class="payment-panel-body">
        <div class="payment-detail-text1">
          <a id="downloadReceiptLink" role="button" class="highlight link" (click)=downloadReceipt()>{{'payment_detail.download_invoice' | translate}}<img class="downloadReceiptImg" src='/assets/images/downloadReceipt.svg'></a>
        </div>
        <div class="payment-detail-text1">
          <p>{{'payment_detail.download_invoice_description' | translate}}</p>
        </div>
      </div>

    </div>
  </div>
</div>
