import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { CommonService } from './core/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showHeaderFooter = true;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly commonService: CommonService,
    private readonly router: Router) { }

  ngOnInit() {
    this.handleLanguage();
    this.loadScript();
    const splitUrl = window.location.href.split('?')[1];
    if (splitUrl && splitUrl.split('=')[0] === 'externalId') {
      this.router.navigate(['search'], {
        queryParams: {
          lang: localStorage.getItem('lang'),
          itemCode: splitUrl.split('=')[1]
        }
      });
    }
  }

  public handleLanguage() {
    this.route.queryParams.subscribe(params => {
      let lang: string;
      const keys = ['en', 'fr', 'nl'];
      if (params && params.lang) {
        lang = params.lang.toLowerCase();
        if ((lang === null) || (!keys.includes(lang))) {
          lang = 'fr';
        }
        if (localStorage.getItem('lang') !== lang) {
          window.location.reload();
        }
        this.commonService.setLocalStorage(lang);
      } else {
        lang = localStorage.getItem('preferedLanKey');
        if (lang) {
          lang = lang.toLowerCase();
        } else {
          lang = 'fr';
        }
        localStorage.clear();
        this.commonService.setLocalStorage(lang);
      }
      this.translate.use(lang);
      document.documentElement.lang = lang;
    });
  }

  public loadScript() {
    let preferredLang = localStorage.getItem('preferedLanKey');
    if (preferredLang) {
      preferredLang = preferredLang.toLowerCase();
    } else {
      preferredLang = 'fr';
    }
    const lang = preferredLang;
    const gtmId = environment.gtmId;
    const headnode = document.createElement('script');
    const gtmScriptContent = `window.dataLayer = window.dataLayer || [];window.dataLayer.push({"language": "${lang}"});window.dataLayer.push({ "content_group": "track_and_trace", "language": "${lang}", "user_status": "", "user_id": "", "event": "page_view" }); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f)})(window,document,"script","dataLayer","${gtmId}")`;
    headnode.innerHTML = gtmScriptContent;
    document.getElementsByTagName('head')[0].appendChild(headnode);

    const bodynode = document.createElement('div');
    const gtmScriptContentBody = '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=$GTM_ID$", height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';
    const bodyNodeContentWithGtmId = gtmScriptContentBody.replace('$GTM_ID$', gtmId);
    bodynode.innerHTML = bodyNodeContentWithGtmId;
    document.getElementsByTagName('body')[0].appendChild(bodynode);

  }
}
