import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../core/services/common.service';
import { FaqService } from '../core/services/faq.service';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { SharedService } from '../core/services/shared.service';
import { TnTModule } from '../shared/enums/tnt-module';


@Component({
  selector: 'app-btr-faq-new',
  templateUrl: './btr-faq-new.component.html',
  styleUrls: ['./btr-faq-new.component.css']
})

export class BtrFaqNewComponent implements OnInit {
  public module = TnTModule.BTR;
  public questions: any = '';
  public language: string;
  public origin: string;
  public backToFaq = false;
  public backToShipmentDetails = false;
  public goToHome = false;
  public loading = false;
  public showFaqErrorBanner = false;

  private routeFaqId: string;

  constructor(
    public readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    public readonly faqService: FaqService,
    private readonly commonService: CommonService,
    private readonly sharedService: SharedService,
    private readonly translateService: TranslateService,
    private readonly googleAnalyticsService: GoogleAnalyticsService) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.origin = this.router.getCurrentNavigation().extras.state.origin;
    }
  }

  ngOnInit() {
    this.loading = true;
    const qParams = this.router.parseUrl(this.router.url).queryParams;
    const faqId = qParams.faqId;
    this.language = localStorage.getItem('lang');

    if (this.origin === 'faqs') {
      this.backToFaq = true;
    } else if (this.origin === 'shipmentDetails') {
      this.backToShipmentDetails = true;
    } else if (!this.origin || this.origin === 'home') {
      this.goToHome = true;
    }

    this.activeRoute.queryParams.subscribe(params => {
      this.routeFaqId = params.faqId;
      if (params && params.lang) {
        this.language = params.lang.toLowerCase();
        this.commonService.setLocalStorage(this.language);
        this.translateService.use(this.language);
      }
      this.faqService.getFAQDetails(faqId, this.language).subscribe(res => {
        if (res.error) {
          this.showFaqErrorBanner = true;
        } else {
          const faqData = res[0];
          $('#externalContainer').html(faqData.faq_body);
          this.questions = faqData.faq_title;
        }
      });
    });
    this.googleAnalyticsService.pushVirtualPageViewEvent(this.language, faqId);
    this.loading = false;

  }

  public goBack() {
    this.sharedService.getPreviousURL().subscribe(message => {
      if (message) {
        let componentList = message.split('?');
        const qParams = {};
        for (const i of componentList[1].split('&')) {
          const j = i.split('=');
          qParams[j[0]] = j[1];
        }
        componentList = componentList[0].split('/');
        const component = componentList[componentList.length - 1];
        this.router.navigate([component], { queryParams: qParams });
      } else {
        this.router.navigate(['faqs'], {
          queryParams: { lang: localStorage.getItem('lang') }
        });
      }
    });
  }

  public goHome() {
    this.commonService.backToHome(this.module);
  }
}
