import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { TnTModule } from '../../enums/tnt-module';
import { TrackingCategory } from '../../enums/tracking-category';

@Component({
  selector: 'app-tnt-activity',
  templateUrl: './tnt-activity.component.html',
  styleUrls: ['./tnt-activity.component.css']
})
export class TntActivityComponent implements OnInit {
  @Input()
  public module = TnTModule.BTR; // defaults to BTR

  @Input() tntActivityText: string;

  public events: any[] = [];
  public trackingCategory: TrackingCategory;

  private language: string;
  private translations: any;

  constructor(
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    const selectedItem = JSON.parse(localStorage.getItem('SelectedItem'));
    this.events = selectedItem[0].events;
    this.trackingCategory = selectedItem[0].trackingCategory;
    this.language = localStorage.getItem('lang').toUpperCase();
    this.translations = JSON.parse(localStorage.getItem('translations_' + localStorage.getItem('lang')));
  }

  public expandIcon(): void {
    let tabStatus;
    const gaService = this.googleAnalyticsService;
    $('.wrapper').off().on('click', '.tnt-activity-block', function () {
      if ($(this).children('a').hasClass('collapsed')) {
        tabStatus = 'open';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
      } else {
        tabStatus = 'close';
        $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
      }
      gaService.pushEventOnBlockClick('tnt_activity', tabStatus);
    });
  }

  public getEventDescription(event: any): string {
    return (event.key[this.language] && event.key[this.language].description) ? event.key[this.language].description : 'No description available';
  }
  public getColor(event: any): string {
    return (event.key[this.language] && event.key[this.language].color) ? event.key[this.language].color : false;
  }

  public getEventDetails(event: any): string {
    let details = `${moment(event.date).locale(localStorage.getItem('lang')).format('dddd DD-MM-YYYY')} | ${event.time}`;
    // Add location details for LMG if provided
    if (this.module === TnTModule.LMG && event.location && event.location.countryCode) {
      details += ` | ${this.translations.countries[event.location.countryCode]}`;
    }
    return details;
  }
}
