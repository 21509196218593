import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  public locale: string;
  public language: string;
  public param: string;

  constructor(private http: HttpClient) { }

  @Cacheable()
  getFAQ(): Observable<any> {
    return this.http
      .get(environment.apiUrl + '/track/faqs').pipe(
        map(data => {
          return data;
        }));
  }
  @Cacheable()
  getFAQDetails(faqId, lang): Observable<any> {
    return this.http
      .get(environment.apiUrl + '/track/faq?faqId=' + faqId + '&lang=' + lang).pipe(
        map(data => {
          return data;
        }));
  }

  @Cacheable()
  getNewFAQ(lang): Observable<any> {
    return this.http
      .get(environment.apiUrl + '/track/faqs?lang=' + lang).pipe(
        map(data => {
          return data;
        }));
  }

  @Cacheable()
  getFaqTitle(faqId, language): Observable<any> {
    return this.http.get(environment.apiUrl + '/track/faq?faqId=' + faqId + '&lang=' + language)
      .pipe(map(data => {
        return data;
      }));
  }

  public getFAQTranslation() {
    this.param = window.location.pathname.split('/')[1];
    this.locale = this.param.split('&')[1];
    if (this.locale != null) {
      this.language = this.locale.split('=')[1];
      this.language = this.language.toLowerCase();
    }
    if (this.language != null && this.language === 'en') {
      return this.http.get('../assets/i18n/en.json');
    } else if (this.language != null && this.language === 'fr') {
      return this.http.get('../assets/i18n/fr.json');
    } else if (this.language != null && this.language === 'nl') {
      return this.http.get('../assets/i18n/nl.json');
    }
  }
}
